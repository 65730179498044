<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate absolute></v-progress-circular>
    </v-overlay>
    <v-tabs v-model="activetab" background-color="primary" dark>
      <v-tab v-for="(tab, index) of tabs" :key="index">
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activetab">
      <v-tab-item v-for="(tab, index) of tabs" :key="index">
        <v-data-table
          :headers="
            tab.name == 'Dead Stock'
              ? headersDeadstock
              : tab.name == 'Sold Out'
              ? headersSoldout
              : headers
          "
          v-model="selected"
          show-select
          :items="tab.filtered && activefilter ? tab.filtered : tab.items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          @current-items="getFiltered"
        >
          <!-- <template
              v-slot:[`group.header`]="{
                group,
                toggle,
                isOpen,
                items,
                headers,
              }"
            >
              <td>
                <v-btn
                  @click="toggle"
                  small
                  icon
                  :ref="group"
                  :data-open="isOpen"
                >
                  <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </td>

              <td>
                <div class="mt-2">
                  <b>{{ group }}</b>
                  <p>{{ items[0].item.description }}</p>
                  <p class="my-1">{{ items[0].item.manufacturergroup }}</p>
                </div>
              </td>
              <td>
                {{ items[0].promotionstep }}
              </td>
              <td>
                <v-chip
                  class="d-block my-1"
                  label
                  v-for="(inv, index) of items[0].inventory"
                  :key="index"
                >
                  {{ inv.location }}: {{ inv.quantityavailable }} ({{
                    inv.totalvalue
                  }}
                  PLN)</v-chip
                >
              </td>
            </template> -->
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData
                    :headers="exportheaders[index] || exportheaders['other']"
                    :items="tab.filtered"
                  />
                </v-col>
                <v-col v-if="false">
                  <!-- <v-select
                        dense
                        v-model="term"
                        :items="terms"
                        label="Terms"
                        item-text="name"
                        item-value="_id"
                        outlined
                        hide-details
                        clearable
                        multiple
                      ></v-select> -->
                </v-col>
                <v-col>
                  <!-- <v-btn
                        @click="getData()"
                        block
                        tile
                        color="primary"
                        :disabled="loading"
                        >Refresh</v-btn
                      > -->
                </v-col>
                <v-spacer></v-spacer>
                <!-- <v-col>
                  <v-btn @click="action" block tile color="primary">Ready To Pack</v-btn>
                    </v-col>-->
                <v-col class="text-right">
                  <v-btn tile color="primary" @click="show = !show">
                    Action & Filters
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="['deadstock'].includes(index)">
                <v-col><v-subheader>Location: </v-subheader></v-col>
                <v-col>
                  <v-select
                    :items="locations"
                    v-model="filterlocations"
                    item-text="name"
                    item-value="_id"
                    label="Location"
                    outlined
                    hide-details
                    return-object
                    @change="filterResult(tab)"
                    dense
                    class="mt-1"
                    clearable
                  ></v-select>
                </v-col>
                <v-col>
                  <v-switch
                    class="mt-0 pt-2"
                    v-model="filterpostponed"
                    label="Postpond"
                    hide-details
                    inset
                    @change="filterResult(tab)"
                  ></v-switch>
                  <v-switch
                    class="ml-2 mt-0 pt-2"
                    v-model="filterreadytoclearance"
                    label="Ready To Clearance"
                    hide-details
                    inset
                    @change="filterResult(tab)"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show || selected.length">
                  <v-divider></v-divider>
                  <v-subheader> Filters</v-subheader>

                  <v-row>
                    <v-col><v-subheader>Manufacturer: </v-subheader></v-col>
                    <v-col>
                      <v-select
                        :items="manufacturers"
                        v-model="filtermanufacturer"
                        item-text="name"
                        item-value="_id"
                        label="Manufacturer"
                        outlined
                        hide-details
                        return-object
                        @change="filterResult(tab)"
                        dense
                        class="mt-1"
                        clearable
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col><v-subheader>Price Group: </v-subheader></v-col>
                    <v-col>
                      <v-select
                        :items="pricegroups"
                        v-model="filterpricegroup"
                        item-text="name"
                        item-value="_id"
                        label="Price Group"
                        outlined
                        hide-details
                        return-object
                        multiple
                        @change="filterResult(tab)"
                        dense
                        class="mt-1"
                        clearable
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row v-if="['deadstock'].includes(index)">
                    <v-col><v-subheader>Sales (12M): </v-subheader></v-col>
                    <v-col>
                      <v-select
                        :items="options"
                        v-model="filtersales"
                        item-text="name"
                        item-value="_id"
                        label="Sales"
                        outlined
                        hide-details
                        @change="filterResult(tab)"
                        dense
                        class="mt-1"
                        clearable
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row v-if="['deadstock'].includes(index)">
                    <v-col><v-subheader>PSL: </v-subheader></v-col>
                    <v-col>
                      <v-select
                        :items="options"
                        v-model="filterpsl"
                        item-text="name"
                        item-value="_id"
                        label="PSL"
                        outlined
                        hide-details
                        @change="filterResult(tab)"
                        dense
                        class="mt-1"
                        clearable
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="['deadstock'].includes(index)">
                    <v-col><v-subheader>Available: </v-subheader></v-col>
                    <v-col>
                      <v-select
                        :items="options"
                        v-model="filteravailable"
                        item-text="name"
                        item-value="_id"
                        label="Available"
                        outlined
                        hide-details
                        @change="filterResult(tab)"
                        dense
                        class="mt-1"
                        clearable
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="!['deadstock', 'soldout'].includes(index)">
                    <v-col><v-subheader>Current Steps: </v-subheader></v-col>
                    <v-col>
                      <v-select
                        :items="currencies"
                        v-model="currency"
                        item-text="name"
                        item-value="_id"
                        label="Currency"
                        outlined
                        hide-details
                        multiple
                        dense
                        @change="filterResult(tab)"
                        class="mt-1"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="promosteps"
                        v-model="filterstep"
                        item-text="name"
                        item-value="_id"
                        label="Step"
                        outlined
                        hide-details
                        @change="filterResult(tab)"
                        dense
                        class="mt-1"
                        clearable
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="!['deadstock'].includes(index)">
                    <v-col
                      ><v-subheader>Days on promotion: </v-subheader></v-col
                    >

                    <v-col>
                      <v-text-field
                        label="Days"
                        placeholder
                        v-model="filterdaysonpromo"
                        outlined
                        dense
                        hide-details
                        clearable
                        @change="filterResult(tab)"
                        class="mt-1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="['soldout'].includes(index)">
                    <v-col
                      ><v-subheader
                        >Days since the last sale:
                      </v-subheader></v-col
                    >

                    <v-col>
                      <v-text-field
                        label="Days"
                        placeholder
                        v-model="filterdayslastsales"
                        outlined
                        dense
                        hide-details
                        clearable
                        @change="filterResult(tab)"
                        class="mt-1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row v-if="!['deadstock'].includes(index)">
                    <v-col><v-subheader>Added: </v-subheader></v-col>

                    <v-col>
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        v-if="index == Object.values(tabs)[activetab]._id"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Added from"
                            placeholder
                            v-model="createdfrom"
                            append-icon="event"
                            readonly
                            v-on="on"
                            outlined
                            dense
                            hide-details
                            clearable
                            class="mt-1"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="createdfrom"
                          :first-day-of-week="1"
                          @change="filterResult(tab)"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        v-if="index == Object.values(tabs)[activetab]._id"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            label="Added to"
                            placeholder
                            v-model="createdto"
                            append-icon="event"
                            readonly
                            v-on="on"
                            outlined
                            dense
                            hide-details
                            clearable
                            class="mt-1"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="createdto"
                          :first-day-of-week="1"
                          @change="filterResult(tab)"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row> -->
                  <v-divider></v-divider>
                  <v-subheader class="mt-4">Actions</v-subheader>
                  <v-row v-if="['deadstock'].includes(index)">
                    <v-col
                      ><v-subheader
                        >Add/Remove to Postponed:
                      </v-subheader></v-col
                    >

                    <v-col>
                      <v-select
                        :items="locations"
                        v-model="filterlocations"
                        item-text="name"
                        item-value="_id"
                        label="Locations"
                        outlined
                        return-object
                        hide-details
                        dense
                        class="mt-1"
                        @change="filterResult(tab)"
                      ></v-select
                    ></v-col>

                    <v-col>
                      <v-btn
                        :disabled="!selected.length || !filterlocations"
                        block
                        tile
                        color="primary"
                        @click="
                          postponedItems(
                            filterlocations,
                            filterpostponed ? true : false
                          )
                        "
                        >Apply for {{ selected.length }} items</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="['deadstock'].includes(index)">
                    <v-col
                      ><v-subheader
                        >Add/Remove to Ready To Clearance:
                      </v-subheader></v-col
                    >

                    <v-col>
                      <v-select
                        :items="locations"
                        v-model="filterlocations"
                        item-text="name"
                        item-value="_id"
                        label="Locations"
                        outlined
                        return-object
                        hide-details
                        dense
                        class="mt-1"
                        @change="filterResult(tab)"
                      ></v-select
                    ></v-col>

                    <v-col>
                      <v-btn
                        :disabled="!selected.length || !filterlocations"
                        block
                        tile
                        color="primary"
                        @click="
                          readytoclearanceItems(
                            filterlocations,
                            filterreadytoclearance ? true : false
                          )
                        "
                        >Apply for {{ selected.length }} items</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      !['deadstock', 'soldout'].includes(index) &&
                      !filteronlyone
                    "
                  >
                    <v-col><v-subheader>Update: </v-subheader></v-col>
                    <v-col>
                      <v-select
                        :items="promotions.filter((p) => p._id == index)"
                        v-model="promotion"
                        item-text="name"
                        item-value="_id"
                        label="Promotions"
                        outlined
                        hide-details
                        dense
                        class="mt-1"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="currencies"
                        v-model="currency"
                        item-text="name"
                        item-value="_id"
                        label="Currency"
                        outlined
                        hide-details
                        multiple
                        dense
                        @change="promotionstep = null"
                        class="mt-1"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="promosteps"
                        v-model="promotionstep"
                        item-text="name"
                        item-value="_id"
                        label="Set Step for Selected Items"
                        outlined
                        hide-details
                        @change="setStepSelected($event, selected)"
                        dense
                        class="mt-1"
                      ></v-select>
                    </v-col>

                    <v-col>
                      <v-btn
                        :disabled="!selected.length || !promotionstep"
                        block
                        tile
                        color="primary"
                        @click="
                          addOrUpdatePromo(promotion, promotionstep, currency)
                        "
                        >Apply for {{ selected.length }} items</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="!['deadstock'].includes(index)">
                    <v-col
                      ><v-subheader>Remove from promotion: </v-subheader></v-col
                    >

                    <v-col>
                      <v-select
                        :items="promotions"
                        v-model="addtopromotion"
                        @change="filterResult(tab)"
                        item-text="name"
                        item-value="_id"
                        label="Promotions"
                        outlined
                        hide-details
                        dense
                        class="mt-1"
                      ></v-select
                    ></v-col>
                    <v-col>
                      <v-select
                        :items="currencies"
                        v-model="currency"
                        item-text="name"
                        item-value="_id"
                        label="Currency"
                        outlined
                        hide-details
                        multiple
                        dense
                        class="mt-1"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-btn
                        :disabled="!selected.length || !addtopromotion"
                        block
                        tile
                        color="primary"
                        @click="
                          addOrUpdatePromo(addtopromotion, null, currency, true)
                        "
                        >Remove {{ selected.length }} items</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="!['soldout', 'deadstock'].includes(index)">
                    <v-col
                      ><v-subheader>Only on this promotion </v-subheader></v-col
                    >

                    <v-col>
                      <v-switch
                        v-model="filteronlyone"
                        :label="tab.name"
                        hide-details
                        inset
                        @change="filterResult(tab)"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      !['soldout'].includes(index) &&
                      (filteronlyone || ['deadstock'].includes(index))
                    "
                  >
                    <v-col><v-subheader>Add to Promotion: </v-subheader></v-col>

                    <v-col>
                      <v-select
                        :items="promotions.filter((p) => p._id != promotion)"
                        v-model="addtopromotion"
                        item-text="name"
                        item-value="_id"
                        label="Promotions"
                        outlined
                        hide-details
                        dense
                        class="mt-1"
                      ></v-select
                    ></v-col>
                    <v-col>
                      <v-select
                        :items="currencies"
                        v-model="currency"
                        item-text="name"
                        item-value="_id"
                        label="Currency"
                        outlined
                        hide-details
                        multiple
                        dense
                        @change="addtopromotionstep = null"
                        class="mt-1"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="promosteps"
                        v-model="addtopromotionstep"
                        item-text="name"
                        item-value="_id"
                        label="Promotion Step for New"
                        outlined
                        hide-details
                        dense
                        class="mt-1"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-btn
                        :disabled="!selected.length || !addtopromotionstep"
                        block
                        tile
                        color="primary"
                        @click="
                          addOrUpdatePromo(
                            addtopromotion,
                            addtopromotionstep,
                            currency
                          )
                        "
                        >Apply for {{ selected.length }} items</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-container>
          </template>
          <template v-slot:item.remove="{ item }">
            <v-btn disabled block tile color="primary">Remove</v-btn>
          </template>
          <template v-slot:item.dayastmodified="{ item }">
            {{ item.dayastmodified }} days ago
          </template>
          <template v-slot:item.promostep="{ item }">
            <v-select
              :items="promosteps"
              v-model="item.promotionstep"
              item-text="name"
              item-value="_id"
              outlined
              hide-details
              @change="setStep($event, item, true)"
              dense
            ></v-select>
            <small>Last Modified: {{ item.dayastmodified }} days ago</small>
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              v-if="item.item"
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="d-block link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
            <small v-if="item.dayslastsales" class="d-block"
              >Last Sales: {{ item.dayslastsales }} days ago</small
            >
            <!-- <small v-if="item.created" class="d-block"
              >Added: {{ item.created }}</small
            > -->
          </template>
          <template v-slot:item.pricing="{ item }">
            {{ item.pricing }}
          </template>
          <template v-slot:item.inventory="{ item }">
            <v-chip
              class="d-block my-1"
              label
              v-for="(inv, index) of Array.isArray(item.inventory)
                ? item.inventory
                : [item.inventory]"
              :key="index"
            >
              <v-icon
                v-if="inv.deadstock"
                left
                :color="inv.deadstock ? 'red' : ''"
              >
                mdi-label
              </v-icon>

              {{ inv.location }}: {{ inv.quantityavailable }} ({{
                inv.totalvalue
              }}
              PLN)</v-chip
            >
          </template>
          <template v-slot:item.stockcategory="{ item }">
            <v-chip
              class="d-block my-1"
              label
              v-for="(inv, index) of Array.isArray(item.inventory)
                ? item.inventory
                : [item.inventory]"
              :key="index"
            >
              {{ inv.location }}: {{ inv.stockcategory }}
            </v-chip>
          </template>
          <template v-slot:item.pln="{ item }">
            <v-text-field
              type="number"
              dense
              :error="!item.price['pln'].price"
              v-model="item.price['pln'].price"
              :label="steps[item.price['pln'].promotionstep]"
              hide-details
              outlined
              clearable
              disabled
            ></v-text-field>
            <small class="d-block">
              RRP:
              {{ item.pricing ? item.pricing["pln"] : "NONE" }} PLN</small
            >
          </template>
          <template v-slot:item.eur="{ item }">
            <v-text-field
              type="number"
              dense
              :error="!item.price['eur'].price"
              v-model="item.price['eur'].price"
              :label="steps[item.price['eur'].promotionstep]"
              hide-details
              outlined
              clearable
              disabled
            ></v-text-field>
            <small>
              RRP:
              {{ item.pricing ? item.pricing["eur"] : "NONE" }} EUR</small
            >
          </template>
          <template v-slot:item.gbp="{ item }">
            <v-text-field
              :key="item.price['gbp'].price"
              type="number"
              dense
              :error="!item.price['gbp'].price"
              v-model="item.price['gbp'].price"
              :label="steps[item.price['gbp'].promotionstep]"
              hide-details
              outlined
              clearable
              disabled
            ></v-text-field>
            <small
              >RRP: ${{ item.pricing ? item.pricing["gbp"] : "NONE" }}</small
            >
          </template>
          <template v-slot:item.promotion="{ item }">
            {{ item.promotion ? item.promotion.name : "" }}
          </template>
          <template v-slot:item.manufacturergroup="{ item }">
            <p class="mb-0">{{ item.manufacturergroup }}</p>
            <small> {{ item.item.pricegroup }}</small>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  data() {
    return {
      activetab: 0,
      tabs: {},
      show: false,
      search: "",
      loading: false,
      activefilter: false,
      menu1: false,
      createdfrom: "",
      menu2: false,
      createdto: "",
      filterpostponed: false,
      filterreadytoclearance: false,
      filterstep: null,
      filterdayslastsales: null,
      filterdaysonpromo: null,
      filteronlyone: false,
      selected: [],
      filteredItems: [],
      manufacturers: [],
      filtermanufacturer: null,
      pricegroups: [],
      filterpricegroup: [],
      filterpsl: null,
      filtersales: null,
      filteravailable: null,
      options: [
        { _id: "yes", name: "Yes" },
        { _id: "no", name: "No" },
      ],
      locations: [
        { _id: "PL", name: "PL" },
        { _id: "UK", name: "UK" },
        { _id: "NL", name: "NL" },
      ],
      filterlocations: null,

      rows: [25, 50, 100, -1],
      headers: [
        { text: "Added", value: "created" },
        { text: "Manufacturer", value: "manufacturergroup" },
        { text: "Item", value: "item", sortable: false },

        //{ text: "MOQ.", value: "moq" },
        //{ text: "Step", value: "promostep" },
        { text: "Last Modified", value: "dayastmodified" },
        { text: "PLN", value: "pln", sortable: false },
        { text: "EUR", value: "eur", sortable: false },
        { text: "GBP", value: "gbp", sortable: false },
        //{ text: "Currency", value: "currency" },
        //{ text: "Base RRP", value: "pricing" },
        { text: "Inventory", value: "inventory", sortable: false },
        // { text: "Remove from Promotions", value: "remove", sortable: false },
      ],
      headersDeadstock: [
        { text: "Manufacturer", value: "manufacturergroup" },
        { text: "Item", value: "item" },
        { text: "Stock Category", value: "stockcategory" },
        // { text: "Step", value: "promostep" },
        // { text: "PLN", value: "pln" },
        // { text: "EUR", value: "eur" },
        // { text: "GBP", value: "gbp" },
        { text: "Inventory", value: "inventory", sortable: false },
        { text: "Sales (12M)", value: "sales" },
        { text: "Available", value: "quantityavailable" },
        { text: "PSL", value: "psl" },
      ],
      headersSoldout: [
        { text: "Added", value: "created" },
        { text: "Manufacturer", value: "item.manufacturergroup" },
        { text: "Item", value: "item", sortable: false },
        { text: "Promotion", value: "promotion", sortable: false },
        { text: "Days on Promotion", value: "dayspromotion" },
        { text: "Last Sales (days ago)", value: "dayslastsales" },
        //{ text: "Remove from Promotions", value: "remove", sortable: false },
        // { text: "Step", value: "promostep" },
        // { text: "PLN", value: "pln" },
        // { text: "EUR", value: "eur" },
        // { text: "GBP", value: "gbp" },
        //{ text: "Inventory", value: "inventory" },
      ],

      exportheaders: {
        deadstock: [
          { text: "Manufacturer", value: "manufacturergroup" },
          { text: "Item", value: "item" },
          { text: "Location", value: "location" },
          { text: "Stock Category", value: "stockcategory" },
          { text: "Total Value", value: "totalvalue" },
          { text: "Postponed", value: "postponed" },
          { text: "Raady To Clearance", value: "readytoclearance" },
          { text: "Available", value: "quantityavailable" },
          { text: "Date Last Sales", value: "datelastsales" },
          { text: "Sales 12m", value: "sales" },
          { text: "PSL", value: "psl" },
          //{ text: "Makes", value: "makes" },
        ],
        soldout: [
          { text: "Added", value: "created" },
          { text: "Manufacturer", value: "manufacturergroup" },
          { text: "Item", value: "item" },
          { text: "Promotion", value: "promotion" },
          { text: "Days on Promotion", value: "dayspromotion" },
          { text: "Last Sales (days ago)", value: "dayslastsales" },
        ],
        other: [
          { text: "Added", value: "created" },
          { text: "Manufacturer", value: "manufacturergroup" },
          { text: "Item", value: "item" },
          { text: "Days on Promotion", value: "dayspromotion" },
          { text: "Last Sales (days ago)", value: "dayslastsales" },
          { text: "Price [PLN]", value: "price_pln" },
          { text: "Price [EUR]", value: "price_eur" },
          { text: "Price [GBP]", value: "price_gbp" },
          { text: "Available [PL]", value: "inventory_PL" },
          { text: "Available [UK]", value: "inventory_UK" },
        ],
      },
      promotionstep: null,
      steps: {
        customprice: "Custom Price",
        minus15baseprice: "Base Price -15%",
        minus20baseprice: "Base Price -20%",
        minus25baseprice: "Base Price -25%",
        minus30baseprice: "Base Price -30%",
        minus35baseprice: "Base Price -35%",
        minus40baseprice: "Base Price -40%",
        minus45baseprice: "Base Price -45%",
        minus50baseprice: "Base Price -50%",
        minus55baseprice: "Base Price -55%",
        totalvalue: "Stock Value",
        minus50totalvalue: "Stock Value -50%",
      },
      promosteps: [
        //{ _id: "tbaprice", name: "Clear Price" },
        //{ _id: "customprice", name: "Custom Price" },
        { _id: "minus15baseprice", name: "Base Price -15%" },
        { _id: "minus20baseprice", name: "Base Price -20%" },
        { _id: "minus25baseprice", name: "Base Price -25%" },
        { _id: "minus30baseprice", name: "Base Price -30%" },
        { _id: "minus35baseprice", name: "Base Price -35%" },
        { _id: "minus40baseprice", name: "Base Price -40%" },
        { _id: "minus45baseprice", name: "Base Price -45%" },
        { _id: "minus50baseprice", name: "Base Price -50%" },
        { _id: "minus55baseprice", name: "Base Price -55%" },
        { _id: "totalvalue", name: "Stock Value" },
        { _id: "minus50totalvalue", name: "Stock Value -50%" },
      ],

      promotion: null,
      promotions: [],

      addtopromotion: null,
      addtopromotionstep: null,

      currencies: [
        { _id: "pln", name: "PLN" },
        { _id: "eur", name: "ERU" },
        { _id: "gbp", name: "GBP" },
      ],
      currency: ["pln", "eur", "gbp"],
    };
  },
  watch: {
    selected(v) {
      this.promotionstep = null;
    },
    activetab(v) {
      console.log(v);

      this.selected = [];
      this.promotionstep = null;
      this.filteronlyone = false;
      this.menu1 = false;
      this.menu2 = false;
      this.filterResult(Object.values(this.tabs)[this.activetab]);
      this.promotion = Object.values(this.tabs)[v]._id;
    },
    filterpostponed(v) {
      if (v) this.filterreadytoclearance = false;
      this.filterResult(Object.values(this.tabs)[this.activetab]);
    },
    filterreadytoclearance(v) {
      if (v) this.filterpostponed = false;
      this.filterResult(Object.values(this.tabs)[this.activetab]);
    },
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.closeAll();
    this.loading = false;
  },
  methods: {
    filterResult(tab) {
      this.activefilter = false;
      this.selected = [];
      console.log("filter", tab._id);
      tab.filtered = tab.items.filter((i) => {
        let status = true;

        if (tab._id == "deadstock") {
          if (this.filterpostponed) {
            this.activefilter = true;
            if (this.filterpostponed != i.postponed) status = false;
          } else {
            this.activefilter = true;
            if (this.filterpostponed != i.postponed) {
              status = false;
            }
          }

          if (this.filterreadytoclearance) {
            this.activefilter = true;
            if (this.filterreadytoclearance != i.readytoclearance)
              status = false;
          } else {
            this.activefilter = true;
            if (this.filterreadytoclearance != i.readytoclearance) {
              status = false;
            }
          }

          if (this.filterlocations) {
            this.activefilter = true;
            if (this.filterlocations.name != i.inventory.location)
              status = false;
          }
        } else this.activefilter = false;

        if (tab._id == "soldout") {
          if (this.addtopromotion) {
            this.activefilter = true;
            if (this.addtopromotion != i.promotion._id) {
              status = false;
            }
          }
        }

        if (this.filtermanufacturer) {
          this.activefilter = true;
          if (this.filtermanufacturer.name != i.item.manufacturergroup)
            status = false;
        }
        if (this.filterpricegroup && this.filterpricegroup.length > 0) {
          this.activefilter = true;
          let pgStatus = this.filterpricegroup.find(
            (pg) => pg.name == i.item.pricegroup
          );
          if (!pgStatus) status = false;
        }
        if (this.filterstep) {
          this.activefilter = true;
          let tmp = [];
          for (const [key, value] of Object.entries(i.price)) {
            if (this.currency.includes(key)) {
              if (this.filterstep != value.promotionstep) tmp.push(false);
              else tmp.push(true);
            }
          }

          if (tmp.length && tmp.every((v) => !v)) status = false;
        }

        // if (this.filterstep) {
        //   this.activefilter = true;
        //   if (this.filterstep != i.promotionstep) status = false;
        // }

        if (this.createdfrom) {
          this.activefilter = true;
          if (new Date(this.createdfrom) > new Date(i.created)) status = false;
        }
        //console.log(this.createdfrom, status);

        if (this.createdto) {
          this.activefilter = true;
          if (new Date(this.createdto) < new Date(i.created)) status = false;
        }

        if (this.filterdaysonpromo) {
          this.activefilter = true;
          if (this.filterdaysonpromo > i.dayspromotion) status = false;
        }
        if (this.filterdayslastsales) {
          this.activefilter = true;
          if (this.filterdayslastsales > i.dayslastsales) status = false;
        }

        if (this.filteronlyone) {
          this.activefilter = true;
          if (this.filteronlyone != i.onlyone) status = false;
        }

        if (this.filteravailable) {
          this.activefilter = true;
          if (this.filteravailable == "yes" && !i.quantityavailable)
            status = false;
          if (this.filteravailable == "no" && i.quantityavailable)
            status = false;
        }
        if (this.filterpsl) {
          this.activefilter = true;
          if (this.filterpsl == "yes" && !i.psl) status = false;
          if (this.filterpsl == "no" && i.psl) status = false;
        }
        if (this.filtersales) {
          this.activefilter = true;
          if (this.filtersales == "yes" && !i.sales) status = false;
          if (this.filtersales == "no" && i.sales) status = false;
        }
        return status;
      });
      console.log("filter", this.activefilter);
      //filteredItems;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
    getFiltered(e) {
      this.filteredItems = e;
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getStockClearance",
        },
        false,
        true
      );
      this.tabs = result.promotions;
      this.manufacturers = result.manufacturers;
      this.pricegroups = result.pricegroups;
      this.promotions = Object.values(result.promotions)
        .filter((promo) => !["deadstock", "soldout"].includes(promo._id))
        .map((promo) => {
          return { name: promo.name, _id: promo._id };
        });
      this.promotion = Object.values(this.tabs)[0]._id;
      this.filterResult(Object.values(this.tabs)[this.activetab]);
      this.loading = false;
    },
    getMinus15BasePrice(currency, value) {
      return value * 0.85;
    },
    getMinus20BasePrice(currency, value) {
      return value * 0.8;
    },
    getMinus25BasePrice(currency, value) {
      return value * 0.75;
    },
    getMinus30BasePrice(currency, value) {
      return value * 0.7;
    },
    getMinus35BasePrice(currency, value) {
      return value * 0.65;
    },
    getMinus40BasePrice(currency, value) {
      return value * 0.6;
    },
    getMinus45BasePrice(currency, value) {
      return value * 0.55;
    },
    getMinus50BasePrice(currency, value) {
      return value * 0.5;
    },
    getMinus25BasePrice(currency, value) {
      return value * 0.75;
    },
    getTotalValue(currency, value) {
      if (currency != "pln") {
        let exchangerate = prompt(
          `Please enter exchange rate PLN->${currency}`,
          1
        );
        if (exchangerate) {
          return (value || 0) / parseFloat(exchangerate);
        }
      } else return value || 0;
    },
    getMinus50TotalValue(currency, value) {
      if (currency != "pln") {
        let exchangerate = prompt(
          `Please enter exchange rate PLN->${currency}`,
          1
        );
        if (exchangerate) {
          return (value * 0.5) / parseFloat(exchangerate);
        }
      } else return value * 0.5;
    },
    setStepSelected(e, items) {
      items.forEach((item) => {
        this.setStep(e, item, false);
      });
    },
    async setStep(e, item, addtoselected) {
      let update = {
        promotionstep: e,
        modified: new Date(),
      };

      ["pln", "eur", "gbp"]
        .filter((c) => this.currency.includes(c))
        .forEach((currency) => {
          if (!item.price[currency])
            item.price[currency] = { price: 0, promotionstep: null };

          let price = item.pricing[currency] || 0;

          let stockvalue = (
            Array.isArray(item.inventory) ? item.inventory : [item.inventory]
          ).reduce(
            (t, i) => {
              t.totalvalue += i.totalvalue;
              t.quantity += i.quantityonhand;
              return t;
            },
            { totalvalue: 0, quantity: 0 }
          );
          stockvalue = stockvalue.quantity
            ? stockvalue.totalvalue / stockvalue.quantity
            : 0;
          if (e == "minus15baseprice")
            price = this.getMinus15BasePrice(currency, price);
          if (e == "minus20baseprice")
            price = this.getMinus20BasePrice(currency, price);
          if (e == "minus25baseprice")
            price = this.getMinus25BasePrice(currency, price);
          if (e == "minus30baseprice")
            price = this.getMinus30BasePrice(currency, price);
          if (e == "minus35baseprice")
            price = this.getMinus35BasePrice(currency, price);
          if (e == "minus40baseprice")
            price = this.getMinus40BasePrice(currency, price);
          if (e == "minus45baseprice")
            price = this.getMinus45BasePrice(currency, price);
          if (e == "minus50baseprice")
            price = this.getMinus50BasePrice(currency, price);
          if (e == "totalvalue")
            price = this.getTotalValue(currency, stockvalue);
          if (e == "minus50totalvalue")
            price = this.getMinus50TotalValue(currency, stockvalue);

          if (e == "tbaprice") {
            price = null;
            item.price[currency].promotionstep = null;
          } else {
            item.price[currency].promotionstep = e;
          }

          item.price[currency].price = price ? Number(price.toFixed(2)) : null;
        });
      if (addtoselected) this.selected.push(item);
      try {
        console.log(update);
        // let result = await service("promotions_items", {
        //   action: "updateOne",
        //   data: [{ _id: item._id }, { $set: update }],
        // });
      } catch (error) {
        alert(error.message);
      }
    },
    async addOrUpdatePromo(promotion, promotionstep, currency, remove) {
      try {
        this.loading = true;

        if (promotionstep && !remove) {
          this.selected.forEach((item) => {
            this.setStep(promotionstep, item, false);
          });
        }

        let result = await service("promotions", {
          action: "updateStockClearance",
          data: this.selected,
          promotion: promotion,
          currency: currency,
          remove: remove,
        });
      } catch (error) {
        alert(error.message);
      }

      this.selected = [];
      this.promotionstep = null;

      await this.getData();
      this.loading = false;
    },
    async postponedItems(location, remove) {
      try {
        this.loading = true;

        let result = await service("promotions", {
          action: "updateStockClearance",
          postponed: true,
          remove: remove,
          data: this.selected,
          location: location,
        });
      } catch (error) {
        alert(error.message);
      }

      this.selected = [];

      await this.getData();
      this.loading = false;
    },
    async readytoclearanceItems(location, remove) {
      try {
        this.loading = true;

        let result = await service("promotions", {
          action: "updateStockClearance",
          readytoclearance: true,
          remove: remove,
          data: this.selected,
          location: location,
        });
      } catch (error) {
        alert(error.message);
      }

      this.selected = [];

      await this.getData();
      this.loading = false;
    },
    closeAll() {
      console.log("closeAll");
      Object.keys(this.$refs).forEach((k) => {
        if (
          this.$refs[k] &&
          this.$refs[k][0].$attrs &&
          this.$refs[k][0].$attrs["data-open"]
        ) {
          this.$refs[k][0].$el.click();
        }
      });
    },
  },
  components: {
    exportData,
  },
};
</script>