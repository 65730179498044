 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab :key="1">Warehouse PL</v-tab>
          <v-tab :key="1">Warehouse NL</v-tab>
          <v-tab :key="2">Warehouse UK</v-tab>
          <v-tab :key="3">DROP SHIP</v-tab>
          <v-tab-item :key="1">
            <v-data-table
              :headers="headers"
              :items="itemsPL"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              show-select
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.specialorders="{ item }">
                <pre>{{ item.specialorders }}</pre>
              </template>
              <template v-slot:item.eta="{ item }">
                <pre>{{ item.eta }}</pre>
              </template>
              <template v-slot:item.trackingnumber="{ item }">
                <v-chip
                  label
                  :color="item.trackingnumber ? 'green' : 'none'"
                  dark
                  >{{ item.trackingnumber ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.stockorder="{ item }">
                <v-chip label :color="item.stockorder ? 'red' : 'none'" dark>{{
                  item.stockorder ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.quantitybackordermemo="{ item }">
                <v-chip
                  label
                  :color="item.quantitybackordermemo ? 'red' : 'none'"
                  dark
                  >{{ item.quantitybackordermemo ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.shippingmethod="{ item }">
                <span>
                  <v-tooltip bottom v-if="item.difshipmethod">
                    <span
                      >Shipping Method different than on original SO! ({{
                        item.originalshippingmethod
                      }})</span
                    >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-alert</v-icon>
                    </template>
                  </v-tooltip>
                  {{ item.shippingmethod }}
                </span>
              </template>
              <template v-slot:item.quantitycommitted="{ item }">
                <v-chip
                  label
                  :color="
                    item.quantitycommitted
                      ? item.quantitytopack == item.quantitycommitted
                        ? 'yellow'
                        : 'green'
                      : 'none'
                  "
                  dark
                  >{{ item.quantitycommitted }}</v-chip
                >
              </template>
              <template v-slot:top>
                <v-divider></v-divider>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn @click="action" block tile color="primary"
                        >Ready To Pack</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-row>
                        <v-col>
                          <v-select
                            :items="customers"
                            v-model="customer"
                            label="Customer"
                            item-text="displayname"
                            item-value="_id"
                            return-object
                            outlined
                            dense
                            clearable
                          ></v-select>
                          <v-text-field
                            v-model="shippingcost"
                            type="number"
                            label="Shipping Cost"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col>
                          <v-textarea
                            outlined
                            label="Message"
                            v-model="message"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item :key="2">
            <v-data-table
              :headers="headers"
              :items="itemsNL"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              show-select
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.specialorders="{ item }">
                <pre>{{ item.specialorders }}</pre>
              </template>
              <template v-slot:item.eta="{ item }">
                <pre>{{ item.eta }}</pre>
              </template>
              <template v-slot:item.trackingnumber="{ item }">
                <v-chip
                  label
                  :color="item.trackingnumber ? 'green' : 'none'"
                  dark
                  >{{ item.trackingnumber ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.stockorder="{ item }">
                <v-chip label :color="item.stockorder ? 'red' : 'none'" dark>{{
                  item.stockorder ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.quantitybackordermemo="{ item }">
                <v-chip
                  label
                  :color="item.quantitybackordermemo ? 'red' : 'none'"
                  dark
                  >{{ item.quantitybackordermemo ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.shippingmethod="{ item }">
                <span>
                  <v-tooltip bottom v-if="item.difshipmethod">
                    <span
                      >Shipping Method different than on original SO! ({{
                        item.originalshippingmethod
                      }})</span
                    >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-alert</v-icon>
                    </template>
                  </v-tooltip>
                  {{ item.shippingmethod }}
                </span>
              </template>
              <template v-slot:item.quantitycommitted="{ item }">
                <v-chip
                  label
                  :color="
                    item.quantitycommitted
                      ? item.quantitytopack == item.quantitycommitted
                        ? 'yellow'
                        : 'green'
                      : 'none'
                  "
                  dark
                  >{{ item.quantitycommitted }}</v-chip
                >
              </template>
              <template v-slot:top>
                <v-divider></v-divider>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn @click="action" block tile color="primary"
                        >Ready To Pack</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-row>
                        <v-col>
                          <v-select
                            :items="customers"
                            v-model="customer"
                            label="Customer"
                            item-text="displayname"
                            item-value="_id"
                            return-object
                            outlined
                            dense
                            clearable
                          ></v-select>
                          <v-text-field
                            v-model="shippingcost"
                            type="number"
                            label="Shipping Cost"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col>
                          <v-textarea
                            outlined
                            label="Message"
                            v-model="message"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item :key="3">
            <v-data-table
              :headers="headers"
              :items="itemsUK"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              show-select
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.specialorders="{ item }">
                <pre>{{ item.specialorders }}</pre>
              </template>
              <template v-slot:item.eta="{ item }">
                <pre>{{ item.eta }}</pre>
              </template>
              <template v-slot:item.trackingnumber="{ item }">
                <v-chip
                  label
                  :color="item.trackingnumber ? 'green' : 'none'"
                  dark
                  >{{ item.trackingnumber ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.stockorder="{ item }">
                <v-chip label :color="item.stockorder ? 'red' : 'none'" dark>{{
                  item.stockorder ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.quantitybackordermemo="{ item }">
                <v-chip
                  label
                  :color="item.quantitybackordermemo ? 'red' : 'none'"
                  dark
                  >{{ item.quantitybackordermemo ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.quantitycommitted="{ item }">
                <v-chip
                  label
                  :color="
                    item.quantitycommitted
                      ? item.quantitytopack == item.quantitycommitted
                        ? 'yellow'
                        : 'green'
                      : 'none'
                  "
                  dark
                  >{{ item.quantitycommitted }}</v-chip
                >
              </template>
              <template v-slot:top>
                <v-divider></v-divider>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn @click="action" block tile color="primary"
                        >Ready To Pack</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-row>
                        <v-col>
                          <v-select
                            :items="customers"
                            v-model="customer"
                            label="Customer"
                            item-text="displayname"
                            item-value="_id"
                            return-object
                            outlined
                            dense
                            clearable
                          ></v-select>
                          <v-text-field
                            v-model="shippingcost"
                            type="number"
                            label="Shipping Cost"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col>
                          <v-textarea
                            outlined
                            label="Message"
                            v-model="message"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item :key="4">
            <v-data-table
              :headers="headers"
              :items="itemsDROPSHIP"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              show-select
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
            >
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.specialorders="{ item }">
                <pre>{{ item.specialorders }}</pre>
              </template>
              <template v-slot:item.eta="{ item }">
                <pre>{{ item.eta }}</pre>
              </template>
              <template v-slot:item.trackingnumber="{ item }">
                <v-chip
                  label
                  :color="item.trackingnumber ? 'green' : 'none'"
                  dark
                  >{{ item.trackingnumber ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.stockorder="{ item }">
                <v-chip label :color="item.stockorder ? 'red' : 'none'" dark>{{
                  item.stockorder ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.quantitybackordermemo="{ item }">
                <v-chip
                  label
                  :color="item.quantitybackordermemo ? 'red' : 'none'"
                  dark
                  >{{ item.quantitybackordermemo ? "Yes" : "No" }}</v-chip
                >
              </template>
              <template v-slot:item.quantitycommitted="{ item }">
                <v-chip
                  label
                  :color="
                    item.quantitycommitted
                      ? item.quantitytopack == item.quantitycommitted
                        ? 'yellow'
                        : 'green'
                      : 'none'
                  "
                  dark
                  >{{ item.quantitycommitted }}</v-chip
                >
              </template>
              <template v-slot:top>
                <v-divider></v-divider>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn @click="action" block tile color="primary"
                        >Ready To Pack</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-row>
                        <v-col>
                          <v-select
                            :items="customers"
                            v-model="customer"
                            label="Customer"
                            item-text="displayname"
                            item-value="_id"
                            return-object
                            outlined
                            dense
                            clearable
                          ></v-select>
                          <v-text-field
                            v-model="shippingcost"
                            type="number"
                            label="Shipping Cost"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col>
                          <v-textarea
                            outlined
                            label="Message"
                            v-model="message"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import sort from "../../dataTable/sort";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "To", value: "destination" },
        { text: "Date", value: "date" },
        { text: "Stock Request", value: "transaction" },
        { text: "Customer/Vendor", value: "customer" },
        { text: "Created From", value: "createdfrom" },
        { text: "Stock Order", value: "stockorder" },
        //{ text: "Shipping Address", value: "shippingaddress" },
        //{ text: "Shipping Method", value: "shippingmethod" },
        { text: "Quantity", value: "quantity" },
        { text: "Committed", value: "quantitycommitted" },
        { text: "Fulfilled", value: "quantityfulfilled" },
        { text: "OnOrder", value: "quantityonorder" },
        { text: "BO", value: "quantitybackordermemo" },
        { text: "PO/MM", value: "specialorders" },
        //{ text: "Internal Comments", value: "memo" },
        { text: "ETA", value: "eta" },
        { text: "Warehouse Comments", value: "warehousememo" },
        { text: "Tracking", value: "trackingnumber" },
        //{ text: "Terms", value: "terms" },
      ],
      items: [],
      itemsPL: [],
      itemsNL: [],
      itemsUK: [],
      //itemsPEDDERSUK: [],
      itemsDROPSHIP: [],
      rows: [100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      customer: null,
      customers: [],
      message: "",

      shippingcost: 0,
    };
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.items.forEach((line) => {
      line.customer.displayname =
        line.customer.displayname || line.customer.name;
      this.customers.push(line.customer);
    });
    this.loading = false;
  },
  watch: {
    selected() {
      this.shippingcost += select.transaction.shippingcost || 0;
    },
  },
  methods: {
    async getData() {
      let result = await service(
        "orders",
        {
          action: "getOpenStockRequest",
        },
        false,
        false
      );
      result.items.forEach((item) => {
        item.shippingaddress =
          item.shippingaddress || item.shipaddressee || item.shipaddress;
      });
      result.items.forEach((item) => {
        if (
          [
            "5e0dbaba9e33df43f0b3a495",
            "5e395c6024f636543c9cf62b",
            "6188f964a2a404c7408e7bce",
            "5e395a5c24f636543c9cf5ac",
            "627cee8a0f148d1cb7e46aa2",
          ].includes(item.location) &&
          !item.customerdropship
        )
          this.itemsPL.push(item);
        if (
          ["66e04fcba16b2e1e7d873be8"].includes(item.location) &&
          !item.customerdropship
        )
          this.itemsNL.push(item);
        if (
          ["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location
          ) &&
          !item.customerdropship
        )
          this.itemsUK.push(item);
        if (
          [
            "5e0dbaba9e33df43f0b3a496",
            "5faa7485371aa739b8a08843",
            "5fd370869659a6154824058e",
            "605db48d8905a260ada139b0",
          ].includes(item.location) &&
          !item.customerdropship
        )
          this.itemsUK.push(item);

        if (item.customerdropship) this.itemsDROPSHIP.push(item);
      });
      //console.log(this.itemsPL);
      //this.items = result.items;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
    action() {
      let location = null;
      //let customer = null;
      let shippingaddress = null;
      let recordtype = null;
      let error = false;
      let createdfrom = [];
      this.selected.forEach((select) => {
        if (shippingaddress && select.shippingaddress != shippingaddress) {
          alert("You cannot select more shipping address");
          error = true;
        }
        shippingaddress = select.shippingaddress;
        if (location && select.location != location) {
          alert("You cannot select more locations");
          error = true;
        }
        location = select.location;
        if (recordtype && select.recordtype != recordtype) {
          alert("You cannot select different record type");
          error = true;
        }
        recordtype = select.recordtype;
        // if (customer && select.customer._id != customer) {
        //   alert("You cannot select more Customer");
        //   error = true;
        // }
        // customer = select.customer._id;
        createdfrom.push(select._id);
      });
      if (!error)
        this.$router.push({
          name: "Transactions",
          params: {
            recordtype:
              recordtype == "salesorder"
                ? "itemfulfillment"
                : "inventorytransfer",
            mode: "create",
            createdfrom: createdfrom,
            location: location,
            message: this.message,
            customer: this.customer,
            packinginstructions: this.packinginstruction,
            shippingmethod: this.shippingmethod,
            codamount: this.codamount,
          },
        });
    },
  },
};
</script>