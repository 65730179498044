<template>
  <div>
    <v-tabs v-model="tab" background-color="primary" dark>
      <v-tab> Stock Check Planner </v-tab>
      <v-tab> Stock Check Summary </v-tab>
      <v-tab>
        <v-badge color="primary">
          <span>Unrecognised Items</span>
          <span slot="badge">{{ items2.length }}</span>
        </v-badge>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <planner></planner>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <v-row>
            <v-col cols="2">
              <v-text-field
                dense
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                outlined
              >
                <template v-slot:append-outer>
                  <exportData :headers="headersExport" :items="items" />
                </template>
              </v-text-field>
              <v-select
                dense
                outlined
                :items="manufacturers"
                v-model="manufacturer"
                label="Manufacturer Group"
                multiple
                hide-details
                class="mt-2"
              >
              </v-select>

              <v-btn
                :disabled="!selected.length"
                @click="createVerification()"
                block
                tile
                color="primary"
                class="mt-3"
                >Create veryfication</v-btn
              >
            </v-col>

            <v-col>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="From:"
                    placeholder
                    v-model="date"
                    append-icon="event"
                    readonly
                    v-on="on"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
              <v-select
                :items="locations"
                v-model="location"
                label="Warehouse"
                item-text="name"
                item-value="_id"
                outlined
                clearable
                hide-details
                class="mt-4 mb-2"
                dense
              ></v-select>
              <v-btn @click="getData()" block tile color="primary" class="mt-3"
                >Generate/Refresh</v-btn
              >
            </v-col>
            <v-col>
              <v-switch
                class="d-block mt-0"
                inset
                v-model="onlyscanned"
                label="Show Scanned Only"
                dense
              ></v-switch>
              <v-switch
                inset
                class="d-block mt-0"
                v-model="hideMultiple"
                label="Hide Multiple Scans"
                dense
              ></v-switch>
              <v-switch
                inset
                class="d-block mt-0"
                v-model="onlyErrors"
                label="Show Only Errors"
                dense
              ></v-switch>
            </v-col>

            <v-col v-if="items.length && !onlyscanned" cols="3">
              <v-subheader class="mb-4">Bins Scanned/Total Bins</v-subheader>
              <v-row>
                <v-col class="py-0">
                  <v-progress-circular
                    :rotate="-90"
                    :size="80"
                    :width="15"
                    :value="progress"
                    color="primary"
                  >
                    {{ Math.ceil(progress) }}%
                  </v-progress-circular>
                </v-col>
                <v-col class="py-0">
                  <v-list dense>
                    <v-list-item
                      ><v-list-item-subtitle
                        >Checked Bins:
                        {{ this.checkedBins }}</v-list-item-subtitle
                      ></v-list-item
                    >
                    <v-list-item
                      ><v-list-item-subtitle
                        >Total Bins: {{ this.bins }}</v-list-item-subtitle
                      ></v-list-item
                    >
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="items.length" cols="3">
              <v-subheader class="mb-4">Scanned/Expected Qty.</v-subheader>
              <v-row>
                <v-col class="py-0">
                  <v-progress-circular
                    :rotate="-90"
                    :size="80"
                    :width="15"
                    :value="progress2"
                    color="primary"
                  >
                    {{ Math.ceil(progress2) }}%
                  </v-progress-circular>
                </v-col>
                <v-col class="py-0">
                  <v-list dense>
                    <v-list-item
                      ><v-list-item-subtitle
                        >Scanned Qty.: {{ this.scanned }}</v-list-item-subtitle
                      ></v-list-item
                    >
                    <v-list-item
                      ><v-list-item-subtitle
                        >Expected Qty.:
                        {{ this.expected }}</v-list-item-subtitle
                      ></v-list-item
                    >
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                v-model="selected"
                show-select
                :items="
                  itemsfiltered.filter(
                    (l) =>
                      (onlyErrors ? l.lastQty != l.lastQuantityonhand : true) &&
                      (!hideMultiple ||
                        (hideMultiple && Object.keys(l.checksSum).length < 2))
                  )
                "
                :loading="loading"
                loading-text="Loading... Please wait"
                item-key="_id"
                :search="search"
                :footer-props="{ itemsPerPageOptions: rows }"
                :custom-filter="filter"
              >
                <template v-slot:item.item="{ item }">
                  <router-link
                    v-if="item.item"
                    :to="{
                      path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
                    }"
                    class="link"
                    >{{ item.item.displayname || item.item.name }}</router-link
                  >
                </template>
                <template v-slot:item.laststockcheck="{ item }">
                  <span v-if="item.laststockcheck">
                    {{
                      new Date(item.laststockcheck).toISOString().substr(0, 10)
                    }}
                    ({{ item.stockchecktype }})</span
                  >
                </template>
                <template v-slot:item.locations="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        class="d-block"
                        label
                        v-if="item.locations.length"
                        v-bind="attrs"
                        v-on="on"
                        >{{ item.locations[0].bin }}
                        {{ item.locations[0] }}</v-chip
                      >

                      <small label v-if="item.locations.length > 1"
                        >+ {{ item.locations.length - 1 }} more</small
                      >
                    </template>
                    <span>{{ item.locations.join(", ") }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.checksSum="{ item }">
                  <div v-for="(check, index) of item.checksSum" :key="index">
                    <v-chip label class="d-block">
                      <v-icon
                        left
                        :color="
                          check.quantity == check.quantityonhand
                            ? !check.quantity
                              ? 'warning'
                              : 'success'
                            : 'error'
                        "
                        >mdi-information-box</v-icon
                      >
                      {{ index }} ({{ check.quantity }}/{{
                        check.quantityonhand
                      }})
                    </v-chip>
                    <small v-for="t of check.check" :key="t._id">
                      <router-link
                        :to="{
                          path: `/transactions/stockcheck/view/${t._id}`,
                        }"
                        class="link"
                        >{{ t.name }}
                      </router-link>
                    </small>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers2"
              :items="items2"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
            >
              <template v-slot:item.item="{ item }">
                <v-combobox
                  class="my-2"
                  v-model="item.item"
                  item-text="name"
                  item-value="_id"
                  :items="itemsList"
                  return-object
                  label="Item"
                  outlined
                  dense
                  hide-details
                  v-on:keyup.enter="getItems(item.item)"
                  @change="changeItem($event, item._id, 'item')"
                  hint="type item name and put enter to find"
                  :disabled="loading"
                >
                </v-combobox>
              </template>
              <template v-slot:item.quantityonhand="{ item }">
                <v-text-field
                  class="my-2"
                  hide-details
                  type="number"
                  label="Qty. Expected"
                  min="0"
                  outlined
                  dense
                  v-model="item.quantityonhand"
                  @change="changeItem($event, item._id, 'quantityonhand')"
                ></v-text-field>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  v-if="item.transaction"
                  :to="{
                    path: `/transactions/stockcheck/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
  <script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
import planner from "./planner.vue";
export default {
  props: ["customer"],
  data() {
    return {
      checked: 0,
      itemsCount: 0,
      checkedBins: 0,
      bins: 0,
      scanned: 0,
      expected: 0,
      manufacturers: [],
      manufacturer: null,
      tab: "overview",
      hideMultiple: false,
      menu: false,
      loading: false,
      itemsList: [],
      onlyErrors: false,
      onlyscanned: true,
      bincontrol: false,
      headers: [
        { text: "Manufacturer", value: "manufacturergroup" },
        { text: "Item", value: "item" },
        //{ text: "Name", value: "name" },
        { text: "Last Stock Check", value: "laststockcheck" },
        { text: "Current on Hand", value: "quantityonhand" },
        { text: "Locations", value: "locations", sortable: false },
        {
          text: "Checks (Scanned/Expected)",
          value: "checksSum",
          sortable: false,
          width: "200px",
        },
        {
          text: "Scanned",
          value: "lastQty",
        },
        {
          text: "Expected",
          value: "lastQuantityonhand",
        },
        {
          text: "Difference (Scanned/Expected)",
          value: "difference",
        },
        {
          text: "Avg. Value",
          value: "price",
        },
      ],
      headersExport: [
        { text: "Manufacturer", value: "manufacturergroup" },
        { text: "Item", value: "item" },
        { text: "Name", value: "name" },
        { text: "Last Stock Check", value: "laststockcheck" },
        { text: "Current on Hand", value: "quantityonhand" },
        { text: "Locations", value: "locations", sortable: false },
        {
          text: "Scanned",
          value: "lastQty",
        },
        {
          text: "Expected",
          value: "lastQuantityonhand",
        },
        {
          text: "Difference (Scanned/Expected)",
          value: "difference",
        },
        {
          text: "Avg. Value",
          value: "price",
        },
      ],
      headers2: [
        { text: "Item", value: "item" },
        { text: "Name", value: "name" },
        { text: "Check.", value: "transaction" },
        { text: "Qty.", value: "quantity" },
        { text: "Bin", value: "bin" },
        { text: "Qty. Expected", value: "quantityonhand" },
        { text: "Employee", value: "employee" },
      ],
      selected: [],
      items: [],
      items2: [],
      rows: [100],
      search: "",
      show: false,
      date: new Date().toISOString().substr(0, 10),
      location: "5e0dbaba9e33df43f0b3a495",
      locations: [
        { name: "PL", _id: "5e0dbaba9e33df43f0b3a495" },
        { name: "UK", _id: "61e14c6ae4a35a668517a8fe" },
      ],
    };
  },
  async created() {
    this.loading = true;
    //await this.getData();
    this.loading = false;
  },
  watch: {
    onlyscanned(v) {
      this.items = [];
      this.items2 = [];
    },
  },
  computed: {
    progress() {
      return (this.checkedBins / this.bins) * 100;
    },
    progress2() {
      return (this.scanned / this.expected) * 100;
    },
    itemsfiltered() {
      this.checked = 0;
      this.scanned = 0;
      this.expected = 0;
      let tmpBins = [];
      let tmpCheckedBins = [];
      this.bins = 0;
      this.checkedBins = 0;

      let filtered = this.items;
      if (this.manufacturer && this.manufacturer.length)
        filtered = filtered.filter((i) =>
          this.manufacturer.includes(i.manufacturergroup)
        );

      filtered.forEach((i) => {
        if (i.laststockcheck) this.checked++;
        this.scanned += i.lastQty;
        this.expected += i.lastQuantityonhand;
        tmpBins.push(...i.locations);
        if (i.checks && i.checks["TBA"])
          tmpCheckedBins.push(...Object.keys(i.checks["TBA"]));
      });
      this.bins = [...new Set(tmpBins)].length;
      
      this.checkedBins = [...new Set(tmpCheckedBins)].length;

      this.itemsCount = filtered.length;
      return filtered;
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      this.checked = 0;
      this.scanned = 0;
      this.expected = 0;

      let result = await service(
        "orders",
        {
          action: "getStockCheck",
          location: this.location,
          date: this.date,
          onlyscanned: this.onlyscanned,
        },
        false,
        false
      );
      this.items = result.items;
      this.items.forEach((i) => {
        if (!this.manufacturers.includes(i.manufacturergroup))
          this.manufacturers.push(i.manufacturergroup);
      });
      this.items2 = result.unrecognised;
      this.bincontrol = result.bincontrol;
      this.loading = false;
    },
    async createVerification() {
      let number = prompt("Please enter number of veryfication:", 1);
      if (number) {
        let lines = [];
        console.log(this.selected);
        this.selected.forEach((s) => {
          let bins = [];
          s.locations.forEach((l) => {
            bins.push(l);
          });
          Object.values(s.checks || {}).forEach((c) => {
            bins.push(...Object.keys(c));
          });
          bins = [...new Set(bins)];
          bins.forEach((b) => {
            lines.push({ item: s.item, bin: b });
          });
        });

        lines.sort((a, b) => {
          const nameA = a.bin.toUpperCase(); // ignore upper and lowercase
          const nameB = b.bin.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });

        localStorage.setItem(
          "data.tmp",
          JSON.stringify({
            recordtype: "stockcheck",
            location: this.location,
            mode: "create",
            stockchecks: lines,
            description: `Verification ${number}`,
          })
        );

        const routeData = this.$router.resolve({
          name: "Transactions",
          params: {
            recordtype: "stockcheck",
            mode: "create",
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async changeItem(e, id, field) {
      let update = {};
      console.log(e);
      if (e) {
        update[field] = e._id || e;
        console.log(update);
        await service("items_stockcheck", {
          action: "updateOne",
          data: [{ _id: id }, { $set: update }],
        });
      }
    },
    async getItems(item) {
      if (item._id) item = item.name;
      this.itemsList = await service("items", {
        action: "find",
        data: [
          {
            recordtype: { $in: ["inventoryitem"] },
            $or: [
              { name: { $regex: `.*${item}.*`, $options: "i" } },
              { displayname: { $regex: `.*${item}.*`, $options: "i" } },
            ],
          },
        ],
        limit: 100,
      }).then((response) => {
        response.forEach((v) => {
          v.name = v.displayname || v.name;
        });
        return response;
      });
    },
  },
  components: {
    exportData,
    planner,
  },
};
</script>
<style scoped>
.v-input__append-outer {
  margin: 0 !important;
}
</style>