<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Related Transactions</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </template>
    </v-tooltip>

    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tab-item>
      <v-container fluid class="py-0">
        <v-alert
          type="warning"
          dense
          v-if="unexpectedscan.length"
          v-model="record.alert"
          dismissible
          close-text="Confirm Alert"
        >
          Unexpected Redarc Scan! ({{ unexpectedscan.join(", ") }})
        </v-alert>
        <v-alert
          type="info"
          dense
          v-if="record.customer && record.customer.shippinginstructions"
        >
          <pre>{{ record.customer.shippinginstructions }}</pre>
        </v-alert>
        <v-alert
          type="warning"
          dense
          v-if="record.customerdropship || record.dropship"
          >DROP SHIPPING</v-alert
        >
        <v-alert
          dense
          v-for="(wz, index) of openif"
          :key="index"
          :type="
            wz[0].shippingmethod != record.shippingmethod.name
              ? 'error'
              : 'success'
          "
          icon="mdi-package-up"
          >Packaging in progress:
          {{ wz.map((r) => r.name).join(", ") }}
          ({{ wz[0].shippingmethod }} - {{ wz[0].shipname }})
        </v-alert>
        <v-row row wrap>
          <v-col>
            <v-subheader>Main</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0" style="max-width: 50%">
                      <v-list-item-subtitle>Customer:</v-list-item-subtitle>

                      <v-list-item-title>
                        <router-link
                          v-if="record.customer"
                          :to="`/users/${record.customer.recordtype}/view/${record.customer._id}`"
                          >{{ record.customer.name }}</router-link
                        >
                      </v-list-item-title>
                    </v-col>
                    <v-col
                      class="py-0"
                      v-if="
                        record.salesrep ||
                        (record.customer && record.customer.salesrep)
                      "
                    >
                      <v-list-item-subtitle>Sales Rep:</v-list-item-subtitle>

                      <v-list-item-title
                        v-if="record.location._id == '62de6196fa2fc522dc692c7e'"
                      >
                        {{
                          record.salesrep
                            ? record.salesrep.name
                            : record.customer.salesrep
                            ? record.customer.salesrep.name
                            : ""
                        }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{
                          record.customer.salesrep
                            ? record.customer.salesrep.name
                            : record.salesrep
                            ? record.salesrep.name
                            : ""
                        }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Date:</v-list-item-subtitle>
                      <v-list-item-title>{{ date }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Created By:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.createdby
                          ? record.createdby.name
                          : record.customer.name
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="primary">local_shipping</v-icon>
                </v-list-item-action>

                <v-list-item-content style="min-height: 121px">
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >{{ shippingaddress.company
                        }}{{ shippingaddress.name }}</v-list-item-subtitle
                      >
                      <v-list-item-title>{{
                        shippingaddress.address
                      }}</v-list-item-title>
                      <v-list-item-title>{{
                        shippingaddress.city
                      }}</v-list-item-title>
                      <v-list-item-title>{{
                        shippingaddress.zip
                      }}</v-list-item-title>
                      <v-list-item-title>{{
                        shippingaddress.country
                      }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Shipping Method:</v-list-item-subtitle
                      >
                      <v-list-item-title>{{
                        record.shippingmethod
                          ? record.shippingmethod.name
                          : " -None- "
                      }}</v-list-item-title>
                      <v-list-item-subtitle v-if="record.point">{{
                        record.point
                      }}</v-list-item-subtitle>
                      <v-list-item-title v-if="record.pointname">{{
                        record.pointname
                      }}</v-list-item-title>
                      <v-list-item-title v-if="record.shippinginstructions">{{
                        record.shippinginstructions
                      }}</v-list-item-title>
                      <v-list-item-title
                        v-if="record.customerdropship || record.dropship"
                      >
                        <v-chip label class="mt-2" dark color="orange"
                          >DROP SHIPPING!</v-chip
                        >
                      </v-list-item-title>
                      <a
                        v-if="record.file2"
                        :href="record.file2.url"
                        target="_blank"
                        class="link d-block"
                        >SHOW LABEL</a
                      >
                      <a
                        v-for="file of record.files"
                        :key="file._id"
                        :href="file.url"
                        target="_blank"
                        class="link d-block"
                        >SHOW LABEL</a
                      >
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item
                v-if="record.createdfrom && record.createdfrom.length"
              >
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Created From:
                        <v-icon
                          small
                          @click="
                            copyToClipboard(
                              record.createdfrom.map((r) => r.name).join(', ')
                            )
                          "
                        >
                          mdi-clipboard-plus-outline
                        </v-icon></v-list-item-subtitle
                      >
                      <v-list-item-title>
                        <div
                          v-for="createdfrom of record.createdfrom"
                          :key="createdfrom._id"
                        >
                          <router-link
                            :to="`/transactions/${createdfrom.recordtype}/view/${createdfrom._id}`"
                          >
                            {{ createdfrom.name }}
                          </router-link>
                        </div>
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0" v-if="baselinker_archived.length">
                      <v-list-item-subtitle
                        >Baselinker Archived Order:</v-list-item-subtitle
                      >
                      <v-list-item-title
                        v-for="order of baselinker_archived"
                        :key="order"
                        >{{ order }}</v-list-item-title
                      >
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Invoices (PDF):</v-list-item-subtitle
                      >
                      <v-list-item-title v-if="record.childs">
                        <a
                          class="link"
                          v-for="correlated of record.childs.reduce(
                            (total, inv) => {
                              if (!total.find((v) => v._id == inv._id))
                                total.push(inv);
                              return total;
                            },
                            []
                          )"
                          target="_blank"
                          :key="correlated._id"
                          :href="`http://localhost:8080/api/pdf/transactions/${
                            correlated._id
                          }/${correlated.name
                            .replaceAll('#', '%23')
                            .replaceAll('/', '%2F')}?exportclearance=true`"
                        >
                          {{ correlated.name }}
                          <br />
                        </a>
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-divider vertical></v-divider>

          <v-col>
            <v-subheader>Details</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Quantity:</v-list-item-subtitle>
                      <v-list-item-title>{{ quantity }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Weight:</v-list-item-subtitle>
                      <v-list-item-title>{{ totalweight }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Items Amount:</v-list-item-subtitle>
                      <v-list-item-title>{{ itemsamount }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Shipping Cost:</v-list-item-subtitle
                      >
                      <v-list-item-title>{{
                        record.shippingcost
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Phone:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        shippingaddress.phone
                      }}</v-list-item-title>
                    </v-col>
                    <v-col class="py-0" v-if="record.customer">
                      <v-list-item-subtitle>E-Mail:</v-list-item-subtitle>
                      <v-list-item-title>{{
                        record.customer.email
                      }}</v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item v-if="record.codamount">
                <v-list-item-action>
                  <v-icon color="primary">mdi-cash-multiple</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-subtitle>COD:</v-list-item-subtitle>
                  <v-list-item-title>{{ codamount }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="record.message || record.note">
                <v-list-item-action>
                  <v-icon color="primary">comment</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <div class="note grey--text">{{ record.message }}</div>
                  <div class="note grey--text">{{ record.note }}</div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="instruction in record.packinginstructions"
                :key="instruction._id"
              >
                <v-list-item-icon>
                  <v-icon>mdi-alert-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ instruction.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col>
            <field-map :addresses="addresses" />
          </v-col>
        </v-row>
        <v-divider v-if="record.comment"></v-divider>
        <v-row>
          <v-col v-if="record.comment">
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">comment</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-subtitle
                  >Customer Service Comments:</v-list-item-subtitle
                >
                <v-list-item-title>{{ record.comment }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-snackbar
          v-model="msg.show"
          :color="msg.color || 'red'"
          :timeout="2000"
          bottom
          absolute
          center
          >{{ msg.message }}</v-snackbar
        >
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <tool-relatedtransactions :record="record" />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: "",
      msg: false,
      baselinker_archived: [],
      itemsRedarc: [],
      //unexpectedscan: [],
      openif: {},
    };
  },
  async created() {
    if (
      this.record.customer &&
      this.record.customer.salesrep &&
      !this.record.customer.salesrep._id
    )
      this.record.customer.salesrep = await service("users", {
        action: "findOne",
        data: [{ _id: this.record.customer.salesrep }],
      });
    if (
      this.record.baselinker_order_id &&
      this.record.baselinker_order_id.length
    )
      for (let order of this.record.baselinker_order_id) {
        let status = await service("baselinker", {
          action: "baselinker_getStatus",
          order: order,
        });
        if (status == "Archived") this.baselinker_archived.push(order);
      }
    if (
      this.record.location &&
      this.record.location._id != "61e14c6ae4a35a668517a8fe"
    )
      this.openif = await this.getopenif();

    this.itemsRedarc = this.record.transactionlines
      .filter(
        (item) =>
          item.manufacturergroup &&
          (item.manufacturergroup._id || item.manufacturergroup) ==
            "5f9bdaf85e3dc023c4682867"
      )
      .map((item) => item.displayname);
  },
  watch: {
    async "record.customer"() {
      if (
        this.record.location &&
        this.record.location._id != "61e14c6ae4a35a668517a8fe"
      )
        this.openif = await this.getopenif();
    },
  },
  methods: {
    async getopenif() {
      console.log("openif");
      return await service("transactions", {
        action: "find",
        data: [
          {
            _id: { $ne: this.record._id },
            recordtype: "itemfulfillment",
            status: { $nin: ["shipped", "packed"] },
            customer: this.record.customer._id,
            shipname: this.record.shipname,
          },
          {
            projection: {
              _id: true,
              name: true,
              shippingmethod: true,
              shipname: true,
            },
          },
        ],
      })
        .then(async (res) => {
          for (let line of res) {
            line.shippingmethod = await service("items", {
              action: "findOne",
              data: [
                {
                  _id: line.shippingmethod,
                },
                {
                  projection: {
                    _id: true,
                    name: true,
                  },
                },
              ],
            });
          }
          return res;
        })
        .then(async (res) => {
          return res.reduce((t, l) => {
            t[`${l.shipname}-${l.shippingmethod._id}`] =
              t[`${l.shipname}-${l.shippingmethod._id}`] || [];

            t[`${l.shipname}-${l.shippingmethod._id}`].push({
              name: l.name,
              shippingmethod: l.shippingmethod.name,
              shipname: l.shipname,
            });
            return t;
          }, {});
        });
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.msg = {
        message: `${text} - copied to clipboard`,
        show: true,
        color: "primary",
      };
    },
  },
  computed: {
    unexpectedscan() {
      if (this.itemsRedarc && this.itemsRedarc.length) {
        let unexpected = (this.record.uniquelabels || []).find(
          (item) => !this.itemsRedarc.includes(item.parameter) && !item.confirmed
        );
        if (unexpected && this.record.alert != false)
          this.$set(this.record, "alert", true);
        if (unexpected) return [unexpected.parameter];
        else {
          this.$set(this.record, "alert", undefined);
          return [];
        }
      }
      return [];
    },
    date() {
      return new Date(this.record.date).toISOString().substr(0, 10);
    },
    quantity() {
      return `${this.record.quantity}/${this.record.quantitytopack}`;
    },
    weight() {
      return this.record.weight
        ? `${parseFloat(this.record.weight).toFixed(2)} kg`
        : `0.00 kg`;
    },
    totalweight() {
      let weight = 0;
      this.record.transactionlines.forEach((line) => {
        weight += parseFloat(line.weight * line.quantitytopack);
      });
      return weight ? `${parseFloat(weight).toFixed(2)} kg` : `0.00 kg`;
    },
    itemsamount() {
      let amount = 0;
      if (this.record.customer && this.record.customer.currency) {
        this.record.transactionlines.forEach((line) => {
          if (line.createdfromline) {
            let createdfromline = this.record.records
              ? this.record.records[line.createdfromline._id]
              : line.createdfromline;
            if (createdfromline)
              amount += parseFloat(createdfromline.price * line.quantity);
          }
        });
        return `${parseFloat(amount).toFixed(2)} ${
          this.record.customer.currency
            ? (
                this.record.customer.currency._id ||
                this.record.customer.currency
              ).toUpperCase()
            : ""
        }`;
      }
    },
    amount() {
      return this.record.amount
        ? `${this.record.currency.symbol} ${parseFloat(
            this.record.amount
          ).toFixed(2)}`
        : `${this.record.currency ? this.record.currency.symbol : ""} 0.00`;
    },
    codamount() {
      return this.record.codamount && this.record.currency.symbol
        ? `${this.record.currency.symbol} ${parseFloat(
            this.record.codamount
          ).toFixed(2)}`
        : `${
            this.record.currency && this.record.currency.symbol
              ? this.record.currency.symbol
              : ""
          } 0.00`;
    },
    addresses() {
      let addresses = [];
      if (this.record.shippingaddress)
        addresses.push(this.record.shippingaddress);
      return addresses;
    },
    shippingaddress() {
      let address = {
        name: "",
        address: "",
        city: "",
        zip: "",
        country: "",
        phone: "",
      };
      if (
        this.record.shipname &&
        this.record.shipname != this.record.shipaddressee &&
        this.record.shipname != this.record.shipaddress
      )
        address.company = `${this.record.shipname}, `;
      address.name = this.record.shipaddressee || "";
      if (this.record.shipaddress) address.address += this.record.shipaddress;
      if (this.record.shipaddress2)
        address.address += `, ${this.record.shipaddress2}`;
      if (this.record.shipzip) address.zip = this.record.shipzip;
      if (this.record.shipcity) address.city += this.record.shipcity;
      if (this.record.shipcountry)
        address.country =
          this.record.shipcountry.name || this.record.shipcountry;

      address.phone = this.record.shipphone;
      return address;
    },
  },
};
</script>
<style scoped>
.v-list-item__subtitle,
.v-list-item__title {
  white-space: unset;
}
</style>
