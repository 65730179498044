 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                this.locationsFilter.includes(tab.location) ||
                !this.locationsFilter.length
            )"
            :key="index"
          >
            <v-badge color="primary">
              <span>{{ tab.name }}</span>
              <span slot="badge">{{ tab.items.length }}</span>
            </v-badge>
          </v-tab>
          <v-tab-item
            v-for="(tab, index) of Object.values(tabs).filter(
              (tab) =>
                this.locationsFilter.includes(tab.location) ||
                !this.locationsFilter.length
            )"
            :key="index"
          >
            <v-data-table
              :headers="headers"
              :items="
                tab.items.filter((item) => {
                  return (
                    (fleetorder ? !!item.fleetorder : !item.fleetorder) &&
                    (dropship ? !!item.dropship : !item.dropship)
                  );
                })
              "
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              v-model="selected"
              show-select
              :search="search"
              :single-select="singleSelect"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :item-class="rowClass"
            >
              <template v-slot:item.date="{ item }">
                <span>{{
                  new Date(item.date).toISOString().substr(0, 10)
                }}</span>
              </template>
              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                  }"
                  class="link"
                  >{{
                    item.customer.displayname || item.customer.name
                  }}</router-link
                >
              </template>
              <template v-slot:item.item="{ item }">
                <span>
                  <router-link
                    :to="{
                      path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
                    }"
                    class="link"
                    >{{ item.item.displayname || item.item.name }}</router-link
                  >
                  <br />
                  {{ item.item.custombox ? "no A/F" : ""
                  }}{{ item.item.tobuild ? "Item To Build" : "" }}
                </span>
              </template>
              <template v-slot:item.additionalservice="{ item }">
                <span
                  v-for="service in item.additionalservice"
                  :key="service._id"
                  >{{ service.displayname || service.name }} <br
                /></span>
              </template>
              <template v-slot:item.manufacturer="{ item }">
                <small>{{ item.manufacturer }} <br /></small>
              </template>
              <!-- <template v-slot:item.correlated="{ item }">
            <router-link
              v-for="po in item.correlated"
              :key="po._id"
              :to="{path: `/${po.collection}/${po.recordtype}/view/${po._id}`}"
              class="link"
            >{{ po.displayname||po.name }}</router-link>
          </template> -->
              <template v-slot:item.dropship="{ item }">
                <v-chip label :color="item.dropship ? 'red' : 'none'" dark>{{
                  item.dropship ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.fleetorder="{ item }">
                <v-chip label :color="item.fleetorder ? 'red' : 'none'" dark>{{
                  item.fleetorder ? "Yes" : "No"
                }}</v-chip>
              </template>
              <template v-slot:item.destination="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-chip
                      v-if="item.destination"
                      small
                      v-on="on"
                      label
                      :color="
                        item.destination &&
                        (item.destination.quantityavailable || 0) +
                          ((item.destination.quantityonorder || 0) +
                            (item.destination.quantityonspecialorder || 0)) -
                          (item.destination.quantitybackorder || 0)
                          ? 'green'
                          : 'grey'
                      "
                      dark
                      >{{
                        (item.destination.quantityavailable || 0) +
                        ((item.destination.quantityonorder || 0) +
                          (item.destination.quantityonspecialorder || 0)) -
                        (item.destination.quantitybackorder || 0)
                      }}</v-chip
                    >
                  </template>

                  <pre class="text-caption" v-if="item.destination">
  {{
                      {
                        Available: item.destination.quantityavailable,
                        OnOrder:
                          (item.destination.quantityonorder || 0) +
                          (item.destination.quantityonspecialorder || 0),
                        BO: item.destination.quantitybackorder || 0,
                      }
                    }}</pre
                  >
                </v-tooltip>
              </template>

              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData :headers="headers" :items="items" />
                    </v-col>
                    <v-col>
                      <v-switch v-model="open" label="Only Open"></v-switch>
                    </v-col>
                    <v-col>
                      <v-switch
                        v-model="fleetorder"
                        label="Fleet Order"
                      ></v-switch>
                    </v-col>
                    <v-col>
                      <v-switch v-model="dropship" label="Drop Ship"></v-switch>
                    </v-col>
                    <v-col>
                      <v-btn
                        @click="getData()"
                        block
                        tile
                        color="primary"
                        class="mt-2 mb-2"
                        >Refresh</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>

                    <v-col class="text-right">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-divider></v-divider>
                      <toolfooter
                        :items="items"
                        :selected="selected"
                        v-if="!loading"
                        :dropship="dropship"
                        :fleetorder="fleetorder"
                        field="openbackorder"
                      />
                    </div>
                  </v-expand-transition>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import footer from "../backorderitems/footer";
import exportData from "../../dataTable/exportData";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "warehouse" },
        { text: "NL", value: "destination" },
        { text: "Date", value: "date" },
        { text: "Sales Order", value: "transaction", sortable: false },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Item", value: "item", sortable: false },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Service", value: "additionalservice" },
        { text: "Quantity", value: "quantity" },
        { text: "BO", value: "quantitybackorder" },
        { text: "OnOrder", value: "quantityonorder" },
        { text: "Fleet Order", value: "fleetorder" },
        { text: "Drop Ship", value: "dropship" },
        { text: "Stock Category", value: "stockcategory" },
        { text: "PSL", value: "psl" },
        { text: "On Order", value: "totalquantityonorder" },
        { text: "Dropship From", value: "dropshipfrom" },
        { text: "Delivery Method", value: "deliverymethod" },
        { text: "PO Comments", value: "pomemo" },
        { text: "Custom Product infomation", value: "memoline" },
        { text: "Created PO", value: "correlated" },
      ],
      items: [],
      rows: [100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      vendor: null,
      vendors: [],
      currency: null,
      currencies: [],
      message: "",
      countries: [],
      address: {},
      deliverymethod: null,
      deliverymethods: [],
      quantity: 0,
      weight: 0,
      amount: 0,
      fleetorder: false,
      open: true,
      dropship: false,
      tabs: {},
      locationsFilter: [],
    };
  },
  async created() {
    this.loading = true;
    this.tabs = {
      PL: {
        name: "Warehouse PL",
        location: "5e0dbaba9e33df43f0b3a495",
        items: [],
        headers: this.headers,
      },
      UK: {
        name: "Warehouse UK (Birmingham)",
        location: "5faa7485371aa739b8a08843",
        items: [],
        headers: this.headers,
      },
      PeddersUK: {
        name: "Warehouse UK",
        location: "61e14c6ae4a35a668517a8fe",
        items: [],
        headers: this.headers,
      },
      BrakesPL: {
        name: "Brakes PL",
        location: "62de6196fa2fc522dc692c7e",
        items: [],
        headers: this.headers,
      },
    };
    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);
    await this.getData();
    this.loading = false;
  },
  methods: {
    rowClass(item) {
      if (item.additionalservice[0] && item.additionalservice[0].color) {
        this.classToStyle(`background:${item.additionalservice[0].color}`);
        return `background:${item.additionalservice[0].color}`;
      }
    },
    async classToStyle(className) {
      setTimeout(function () {
        let tmp = document.getElementsByClassName(className);
        Array.from(tmp).forEach((el) => {
          el.setAttribute("style", className);
        });
      }, 100);
    },
    async getData() {
      this.loading = true;
      this.tabs["PL"].items = [];
      this.tabs["UK"].items = [];
      this.tabs["PeddersUK"].items = [];
      this.tabs["BrakesPL"].items = [];
      let result = await service(
        "orders",
        {
          action: "getAdditionalServiceOrders",
          dropship: this.dropship,
          fleetorder: this.fleetorder,
          open: this.open,
        },
        false,
        true
      );
      result.items.forEach((item) => {
        if (item.company && ["maincompany"].includes(item.company)) {
          this.tabs["PL"].items.push(item);
        }
        if (
          item.company &&
          ["ozpartsuklimited"].includes(item.company) &&
          item.location &&
          !["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location
          )
        ) {
          this.tabs["UK"].items.push(item);
        }
        if (
          item.location &&
          ["61e14c6ae4a35a668517a8fe", "620bd994628dd8bb034ce62f"].includes(
            item.location
          )
        ) {
          this.tabs["PeddersUK"].items.push(item);
        }
        if (item.company && ["brakespl"].includes(item.company)) {
          this.tabs["BrakesPL"].items.push(item);
        }
      });
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
  },
  components: {
    toolfooter: footer,
    exportData: exportData,
  },
};
</script>