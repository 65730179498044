<template>
  <div>
    <v-row>
      <v-col>
        <vuevectormap
          class="ma-2"
          map="world"
          ref="map"
          width="750px"
          height="400px"
          :regionsSelectable="true"
          :regionsSelectableOne="true"
          :selectedRegions="selectedRegions"
          @regionSelected="test"
          :series="series"
          :key="loading"
        >
        </vuevectormap
      ></v-col>
      <v-col>
        <v-row class="ma-2">
          <v-col>
            <v-autocomplete
              :items="countries"
              v-model="country"
              item-text="name"
              item-value="_id"
              label="Country"
              class="mt-2 mb-2"
              outlined
              clearable
              hide-details
              dense
            >
            </v-autocomplete>
            <v-text-field
              label="Zip Code"
              :disabled="true"
              v-model="zip"
              readonly
              outlined
              dense
              hide-details
              clearable
              class="mt-2 mb-2"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Weight"
              :disabled="!country"
              v-model="weight"
              @change="calcWeight"
              outlined
              dense
              hide-details
              clearable
              class="mt-2 mb-2"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          ><v-col>
            <v-data-table
              v-if="country"
              :headers="headers"
              :items="filteredMethods"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
              :custom-sort="sort"
              :single-select="singleSelect"
              v-model="selected"
              :group-by="group"
            >
              <template v-slot:item.location="{ item }">
                <span>{{
                  item.location.displayname || item.location.name
                }}</span>
              </template>
              <template v-slot:item.shippingcarrier="{ item }">
                <v-row v-if="item.shippingcarrier">
                  <v-col v-if="item.logo">
                    <v-img
                      max-height="24"
                      max-width="24"
                      :src="item.logo.url"
                    ></v-img
                  ></v-col>
                  <v-col v-else>
                    <span>{{ item.shippingcarrier.name }}</span>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item.transaction="{ item }">
                <router-link
                  :to="{
                    path: `/${item.transaction.collection}/${item.transaction.recordtype}/edit/${item.transaction._id}`,
                  }"
                  class="link"
                  >{{
                    item.transaction.displayname || item.transaction.name
                  }}</router-link
                >
              </template>

              <template v-slot:item.shipmethod="{ item }">
                <span>{{ item.shipmethod ? item.shipmethod.name : "" }}</span>
              </template>
              <template v-slot:item.statusref="{ item }">
                <span>{{ item.statusref ? item.statusref.name : "" }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
  <script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import sort from "../../dataTable/sort";

export default {
  data() {
    return {
      loading: false,
      headers: [
        //{ text: "Location", value: "location" },
        { text: "Carrier", value: "shippingcarrier" },
        { text: "Name", value: "name" },
        //{ text: "Display Name", value: "displayname" },
        { text: "Description", value: "description" },
        { text: "Estimated Cost", value: "cost" },
      ],

      items: [],
      weight: 0,
      rows: [-1],
      search: "",
      show: false,
      statuslist: [],
      locationsFilter: [],
      singleSelect: false,
      selected: [],
      countries: [],
      country: null,
      active: 0,
      selectedRegions: ["PL"],
      available: [],
      // Series
    };
  },
  async created() {
    this.loading = true;
    await this.getData();
    this.countries = await service("countries", {
      action: "find",
      data: [{}],
      limit: 260,
    });
    this.loading = false;
  },
  computed: {
    series() {
      return {
        regions: [
          {
            attribute: "fill",
            legend: {
              title: "Available",
            },
            scale: {
              scale1: "#464d69",
            },
            values: this.available.reduce((t, v) => {
              t[v] = "scale1";
              return t;
            }, {}),
          },
        ],
      };
    },
  },
  watch: {
    async country(v) {
      this.filteredMethods = this.items.filter((e) =>
        e.incountries.some((c) => c._id == v)
      );
      if (this.weight) await this.calcWeight(this.weight);

      this.items.forEach((item) => {
        if (item.descriptionstable) {
          let description = item.descriptionstable.find(
            (dsc) => dsc.country == v
          );
          if (description) item.description = description.description;
        }
      });

      this.selectedRegions = [v];
    },
    // async weight(v) {
    //   this.loading = true;
    //   for (let method of this.filteredMethods) {
    //     method.cost = await this.shippingCost(method, v);
    //   }
    //   this.loading = false;
    // },
  },
  methods: {
    async calcWeight(v) {
      let currency = this.country == "PL" ? "pln" : "eur";
      this.loading = true;
      for (let method of this.filteredMethods) {
        method.cost = await this.shippingCost(method, v, currency);
      }
      this.loading = false;
    },
    test(a, b, c) {
      this.selectedRegions = c;
      this.country = a;
      console.log(a, b, c);
    },
    async shippingCost(shippingmethod, weight, currency) {
      let transactionlines = [{ item: { weight: weight }, weight: weight }];

      if (
        shippingmethod.weightunderamount &&
        shippingmethod.weightunderamount < weight
      ) {
        return `Max. weight: ${shippingmethod.weightunderamount}kg`;
      }

      if (
        shippingmethod.weightoveramount &&
        shippingmethod.weightoveramount > weight
      ) {
        return `min. weight: ${shippingmethod.weightoveramount}kg`;
      }

      return await service(
        "items",
        {
          action: "getShippingCost",
          weight: weight,
          shippingmethod: shippingmethod,
          currency: currency,
          location: "weight",
          customer: "guest",
          transactionlines: transactionlines,
        },
        false,
        false
      ).then((res) => {
        return `${res} ${currency.toUpperCase()}`;
      });
    },
    async getData() {
      this.loading = true;
      let result = await service(
        "items",
        {
          action: "getShippingMethods",
        },
        false,
        false
      );
      this.items = result.items;
      this.items.forEach((i) => {
        if (i.incountries) {
          this.available.push(...i.incountries.map((c) => c._id));
        }
      });
      console.log(this.available);
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
  },
};
</script>
<style>
.jvm-series-container {
  display: none;
}
.jvm-tooltip {
  border-radius: 3px;
  background-color: #424242 !important;
}
</style>