<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
    :readonly="
      ($route.params.mode == 'view' ||
        this.formSettings.disabled ||
        this.fieldSettings.disabled == true ||
        record.archived) &&
      !this.formSettings.show
    "
    :disabled="
      ($route.params.mode == 'view' ||
        this.formSettings.disabled ||
        this.fieldSettings.disabled == true ||
        record.archived) &&
      !this.formSettings.show
    "
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        placeholder
        v-model="date"
        append-icon="event"
        :rules="[rules.required]"
        readonly
        v-on="on"
        outlined
        dense
        clearable
        @click:clear="emitChangedValue(true)"
        persistent-hint
        :hint="
          record.delivery && ['salesorder'].includes(record.parentrecordtype)
            ? record.delivery.name
            : ''
        "
      ></v-text-field>
    </template>
    <v-card>
      <v-date-picker
        :allowed-dates="allowedDates"
        :events="allowedEvents"
        v-model="date"
        @input="emitChangedValue()"
        @click:date="dateClick"
        :first-day-of-week="1"
      ></v-date-picker>
      <v-divider></v-divider>
      <v-select
        v-if="record.delieriesTransitObj"
        dense
        class="mx-4 py-4"
        item-text="displayname"
        item-value="_id"
        :items="record.delieriesTransitObj"
        label="Delivery"
        @change="setDelivery"
        return-object
        v-model="delivery"
        :hint="
          record.delivery && ['salesorder'].includes(record.parentrecordtype)
            ? record.delivery.name
            : ''
        "
        persistent-hint
        clearable
        @click:clear="setDelivery($event, true)"
      >
        <template v-slot:selection="{ item }">
          {{ item.displayname }}
        </template>

        <template v-slot:item="{ item }">
          {{ item.displayname }} ({{ item.quantity }})
        </template>
      </v-select>
    </v-card>
  </v-menu>
</template>

<script>
/* eslint-disable */

export default {
  props: ["value", "record", "fieldSettings", "formSettings"],
  data() {
    return {
      date: this.value,
      delivery: this.record.delivery,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      menu: false,
      modal: false,
      rules: {
        required: (value) => (this.required ? !!value || "Required." : true),
      },
    };
  },
  methods: {
    allowedDates(val) {
      if (
        this.record.delivery &&
        ["salesorder"].includes(this.record.parentrecordtype)
      ) {
        return !!(
          val ==
          new Date(this.record.delivery.eta2 || this.record.delivery.eta)
            .toISOString()
            .substr(0, 10)
        );
      } else return true;
    },

    allowedEvents(val) {
      if (
        this.record.delieriesTransitObj &&
        ["salesorder"].includes(this.record.parentrecordtype)
      ) {
        let event = this.record.delieriesTransitObj.find(
          (del) =>
            new Date(del.eta2 || del.eta).toISOString().substring(0, 10) == val
        );
        if (event) return true;
      }
      return false;
    },
    dateClick(val) {
      if (
        this.record.delieriesTransitObj &&
        ["salesorder"].includes(this.record.parentrecordtype)
      ) {
        let event = this.record.delieriesTransitObj.find(
          (del) =>
            new Date(del.eta2 || del.eta).toISOString().substring(0, 10) == val
        );
        if (event) this.record.delivery = this.setDelivery(event);
      }
    },
    setDelivery(event, clear) {
      this.$emit("emit", {
        field: "delivery",
        value: clear ? null : event,
      });
      this.delivery = event;
    },
    emitChangedValue(clear) {
      if (clear && this.delivery) this.setDelivery(null, true);
      else {
        this.$emit("emit", {
          field: this.field,
          value:
            this.date && !clear
              ? new Date(new Date(this.date).toISOString().substr(0, 10))
              : null,
        });
      }
    },
  },

  created() {
    this.date = this.value
      ? new Date(this.value).toISOString().substr(0, 10)
      : null;
  },
  watch: {
    date(value) {
      //console.log(value)
      //if(!value) this.emitChangedValue();
    },
    record: {
      handler: async function (after, before) {
        if (this.formSettings.show) this.field = "value";
        if (
          (this.record[this.field] || this.record.value) != new Date(this.date)
        ) {
          this.date =
            this.record[this.field] || this.record.value
              ? new Date(this.record[this.field] || this.record.value)
                  .toISOString()
                  .substr(0, 10)
              : null;
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.v-menu__content .v-date-picker-title {
  height: 70px;
}
.v-menu__content .v-picker {
  border-radius: 0 !important;
  box-shadow: none;
}
</style>