var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._l((Object.values(_vm.tabs).filter(
            function (tab) { return this$1.locationsFilter.includes(tab.location) ||
              !this$1.locationsFilter.length; }
          )),function(tab,index){return _c('v-tab',{key:index},[_c('v-badge',{attrs:{"color":"primary"}},[_c('span',[_vm._v(_vm._s(tab.name))]),_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(tab.items.length))])])],1)}),_vm._l((Object.values(_vm.tabs).filter(
            function (tab) { return this$1.locationsFilter.includes(tab.location) ||
              !this$1.locationsFilter.length; }
          )),function(tab,index){return _c('v-tab-item',{key:index},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":tab.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","show-select":"","search":_vm.search,"single-select":_vm.singleSelect,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"To Order","single-line":"","type":"number"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.item",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.item.collection) + "/" + (item.item.recordtype) + "/view/" + (item.item._id)),
                }}},[_vm._v(_vm._s(item.item.displayname || item.item.name))])]}},{key:"item.destination",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(item.destination)?_c('v-chip',_vm._g({attrs:{"small":"","label":"","color":item.destination &&
                      (item.destination.quantityavailable || 0) +
                        ((item.destination.quantityonorder || 0) +
                          (item.destination.quantityonspecialorder || 0)) -
                        (item.destination.quantitybackorder || 0)
                        ? 'green'
                        : 'grey',"dark":""}},on),[_vm._v(_vm._s((item.destination.quantityavailable || 0) + ((item.destination.quantityonorder || 0) + (item.destination.quantityonspecialorder || 0)) - (item.destination.quantitybackorder || 0)))]):_vm._e()]}}],null,true)},[(item.destination)?_c('pre',{staticClass:"text-caption"},[_vm._v(_vm._s({
                      Available: item.destination.quantityavailable,
                      OnOrder:
                        (item.destination.quantityonorder || 0) +
                        (item.destination.quantityonspecialorder || 0),
                      BO: item.destination.quantitybackorder || 0,
                    }))]):_vm._e()])]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.locations,"label":"Location","item-text":"name","item-value":"_id","outlined":"","dense":"","hide-details":"","multiple":"","clearable":""},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headers,"items":_vm.items}})],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{staticClass:"mt-0 mb-0",attrs:{"dense":"","label":"Show All"},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}})],1)]}}],null,true)},[_c('span',[_vm._v("Monkfish Automotive LTD from Kidderminster Warehouse")])])],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Refresh")])],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-divider'),(!_vm.loading)?_c('toolfooter',{attrs:{"items":_vm.items,"selected":_vm.selected,"field":"quantity"}}):_vm._e()],1)])],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }