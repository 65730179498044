<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Open Orders</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-checks</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <!-- <v-tooltip bottom>
      <span>Deliveries</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>local_shipping</v-icon>
        </v-tab>
      </template>
    </v-tooltip>-->
    <v-tooltip bottom>
      <span>Related Transactions</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Statistics</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-chart-line</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tab-item>
      <v-container fluid class="py-0">
        <v-alert v-if="record.hidden" type="info" dense>Hidden Item </v-alert>

        <v-alert
          v-if="duplicatebarcode"
          dense
          icon="mdi-barcode-off"
          type="error"
        >
          Duplicate Barcode
        </v-alert>
        <v-alert v-if="newitem" dense type="warning" icon="mdi-new-box">
          New Item
        </v-alert>
        <v-row row wrap>
          <v-col>
            <v-subheader>Main</v-subheader>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-title> {{ record.name }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ record.description }}
                      </v-list-item-subtitle>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Barcode:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ record.barcode }}
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Weight:</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ record.weight }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Manufacturer:</v-list-item-subtitle>

                      <v-list-item-title>
                        {{ record.manufacturer }} ({{
                          record.manufacturergroup
                            ? record.manufacturergroup.name
                            : ""
                        }})
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle
                        >Manufacturer Stock:</v-list-item-subtitle
                      >

                      <v-list-item-title>
                        {{ manufacturerstock }}
                      </v-list-item-title>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Last Update:</v-list-item-subtitle>

                      <v-list-item-title>
                        {{
                          record.manufacturerstockdate
                            ? new Date(record.manufacturerstockdate)
                                .toISOString()
                                .substr(0, 10)
                            : ""
                        }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-list-item-subtitle>Price Group:</v-list-item-subtitle>

                      <v-list-item-title>
                        {{ record.pricegroup ? record.pricegroup.name : "" }}
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-divider vertical></v-divider>

          <v-col style="max-width: 50vw">
            <v-carousel
              :continuous="false"
              hide-delimiter-background
              height="400"
            >
              <v-carousel-item
                v-for="(item, i) in photosrc"
                :key="i"
                :src="item.url"
                reverse-transition="fade-transition"
                transition="fade-transition"
              ></v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <div
            style="display: contents"
            v-for="inventory of quantityavailable.filter((inventory) => {
              return !inventory.hidden;
            })"
            :key="inventory._id"
          >
            <v-col>
              <v-subheader
                >{{ inventory.location.name }}
                {{
                  inventory.stockcategory
                    ? "(" + inventory.stockcategory.name + ")"
                    : ""
                }}</v-subheader
              >
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="py-0">
                        <v-list-item-subtitle>Available :</v-list-item-subtitle>

                        <v-list-item-title>
                          {{ inventory.quantityavailable || 0 }}
                        </v-list-item-title>
                      </v-col>
                      <v-col class="py-0">
                        <v-list-item-subtitle>On Hand:</v-list-item-subtitle>

                        <v-list-item-title>
                          {{ inventory.quantityonhand || 0 }}
                        </v-list-item-title>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col class="py-0">
                        <v-list-item-subtitle>BO:</v-list-item-subtitle>

                        <v-list-item-title>
                          {{ inventory.quantitybackorder || 0 }}
                        </v-list-item-title>
                      </v-col>
                      <v-col class="py-0">
                        <v-list-item-subtitle>On Order:</v-list-item-subtitle>

                        <v-list-item-title>
                          {{ inventory.quantityonorder || 0 }}
                        </v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <!-- <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Total Value:</v-list-item-subtitle
                        >
                        <v-list-item-title>{{
                          inventory.totalvalue || 0
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item> -->
              </v-list>
            </v-col>
            <v-divider vertical></v-divider>
          </div>
        </v-row>
      </v-container>
    </v-tab-item>

    <v-tab-item>
      <tool-openorderstatus :item="record._id" :customheaders="customheaders" />
    </v-tab-item>
    <!-- <v-tab-item>
      <tool-itemsindeliveries :item="record._id" />
    </v-tab-item>-->
    <v-tab-item>
      <tool-relatedtransactions :record="record" />
    </v-tab-item>
    <v-tab-item>
      <statistics :item="record._id" />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
import service from "../../../api/index";
import statistics from "../../../components/tools/itemstatistics/View";
/* eslint-disable */
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      duplicatebarcode: false,
      photosrc: [
        { url: "http://www.ozparts.eu/DBA4654S-10_LR_FRONT.jpg" },
        { url: "http://www.ozparts.eu/T3_4000_Kangaroo-Paw" },
      ],
      customheaders: [
        //{ text: "Location", value: "location", sortable: false },
        { text: "Date of Order", value: "date", sortable: false },
        { text: "Sales Order", value: "salesorder", sortable: false },
        { text: "Reference Number", value: "referencenumber" },
        { text: "Customer", value: "customer", sortable: false },
        {
          text: "Qty. Open",
          value: "quantityopen",
          sortable: false,
          summary: true,
        },
        { text: "Committed", value: "quantitycommitted", sortable: false },
        { text: "Service", value: "additionalservice" },
        { text: "SO#", value: "correlatedtransaction" },
        { text: "ETA", value: "eta", sortable: false },
        { text: "ETA Memo", value: "etamemo", sortable: false },
        { text: "Internal Comments", value: "memomain", sortable: false },
        { text: "Send Together", value: "sendtogether", sortable: false },
        { text: "Fleet Order", value: "fleetorder", sortable: false },
        { text: "Dropship", value: "dropship", sortable: false },
        {
          text: "Ship Date",
          value: "planneddateofshipment",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    record: {
      handler: async function (after, before) {
        if (this.record.photos && this.record.photos.length > 0) {
          this.photosrc = this.record.photos;
        }
      },
      deep: true,
    },
  },
  async created() {
    if (this.record.photos && this.record.photos.length > 0)
      this.photosrc = this.record.photos;
    this.record["inventorydetails"].sort((a, b) =>
      a.location._id < b.location._id ? -1 : 1
    );
    if (this.record.barcode) {
      return await service("items", {
        action: "findOne",
        data: [{ _id: { $ne: this.record._id }, barcode: this.record.barcode }],
      })
        .catch((err) => false)
        .then((res) => {
          this.duplicatebarcode = res ? true : false;
        });
    } else this.duplicatebarcode = false;
  },
  computed: {
    newitem() {
      return Math.round(
        Math.abs((new Date() - new Date(this.record.created)) / 86400000)
      ) < 365
        ? true
        : false;
    },
    totalvalue() {
      let totalvalue = 0;
      this.record["inventorydetails"].forEach((line) => {
        totalvalue += line.totalvalue;
      });
      return totalvalue.toFixed(2);
    },
    manufacturerstock() {
      let tmp = [];
      let components = this.record["components"];
      for (let component of components) {
        tmp.push(component.item.manufacturerstock / component.quantity);
      }

      return Math.max(Math.floor(Math.min(...tmp)), 0);
    },
    quantityavailable() {
      let tmp = {};
      let newinventorydetails = [];
      let components = this.record["components"];
      for (let component of components) {
        let inventorydetails = this.record["inventorydetails"];
        inventorydetails
          .filter((item) => component.item._id == item.item._id)
          .forEach((inventory) => {
            tmp[inventory.location.name] = tmp[inventory.location.name] || {
              quantityavailable: [],
              quantityonhand: [],
              location: inventory.location,
              hidden: inventory.hidden,
            };
            tmp[inventory.location.name].quantityavailable.push(
              inventory.quantityavailable / component.quantity
            );
            tmp[inventory.location.name].quantityonhand.push(
              inventory.quantityonhand / component.quantity
            );
          });
      }
      for (let [key, value] of Object.entries(tmp)) {
        let quantityavailable =
          value.quantityavailable.length == components.length
            ? Math.floor(Math.min(...value.quantityavailable))
            : 0;
        let quantityonhand =
          value.quantityavailable.length == components.length
            ? Math.floor(Math.min(...value.quantityonhand))
            : 0;
        newinventorydetails.push({
          location: value.location,
          hidden: value.hidden,
          quantityavailable: quantityavailable,
          quantityonhand: quantityonhand,
        });
      }
      return newinventorydetails;
    },
  },
  components: {
    statistics,
  },
};
</script>
<style scoped>
.v-list-item__subtitle,
.v-list-item__title {
  white-space: unset;
}
</style>
