 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="
            beta ? betaheaders : simple || lowstock ? headerssimple : headers
          "
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          v-model="selected"
          show-select
          :search="search"
          :single-select="singleSelect"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          :custom-sort="sort"
        >
          <template v-slot:item.quantity="{ item }">
            <v-text-field
              label="To Order"
              single-line
              type="number"
              v-model="item.quantity"
            ></v-text-field>
          </template>
          <template v-slot:item.quantity2="{ item }">
            <v-text-field
              label="To Order"
              single-line
              type="number"
              v-model="item.quantity2"
            ></v-text-field>
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:item.tobuild="{ item }">
            <v-chip label :color="item.tobuild ? 'red' : 'none'" dark>{{
              item.tobuild ? "Yes" : "No"
            }}</v-chip>
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData
                    :headers="
                      beta
                        ? betaheaders
                        : simple || lowstock
                        ? headerssimple
                        : headers
                    "
                    :items="items"
                  />
                </v-col>
                <v-col>
                  <v-select
                    :items="locations"
                    v-model="location"
                    label="Location"
                    item-text="name"
                    item-value="_id"
                    outlined
                    clearable
                    multiple
                    hide-details
                    dense
                  ></v-select>
                  <v-switch
                    v-if="location.length > 1"
                    class="mt-0 mb-0"
                    v-model="join"
                    label="Join Inventory"
                  ></v-switch>
                  <v-combobox
                    class="mt-2"
                    :items="vendors"
                    v-model="vendor"
                    label="Vendor"
                    item-text="displayname"
                    item-value="_id"
                    outlined
                    dense
                    clearable
                    hide-details
                    :loading="loading"
                  ></v-combobox>
                </v-col>

                <v-col>
                  <v-combobox
                    :items="deliverymethods"
                    v-model="deliverymethod"
                    label="Delivery method"
                    item-text="name"
                    item-value="_id"
                    outlined
                    dense
                    clearable
                    hide-details
                    :loading="loading"
                  ></v-combobox>
                  <v-select
                    class="mt-2"
                    :items="pricegroups"
                    v-model="pricegroup"
                    label="Price Group"
                    item-text="name"
                    item-value="_id"
                    outlined
                    clearable
                    multiple
                    hide-details
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-switch
                    v-if="!beta"
                    v-model="simple"
                    label="Simple Algorithm"
                    hide-details
                    dense
                  ></v-switch>
                  <v-switch
                    v-if="!beta"
                    v-model="lowstock"
                    label="Low Stock Algorithm"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <v-col>
                  <v-btn @click="getData()" block tile color="primary"
                    >Refresh</v-btn
                  >
                  <v-switch
                    v-model="beta"
                    label="Beta Test Algorithm"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <v-spacer></v-spacer>

                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show">
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model.number="days"
                        label="Default Days"
                        outlined
                        min="1"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        v-model.number="daysAX"
                        label="AX"
                        outlined
                        min="1"
                        type="number"
                      ></v-text-field>
                      <v-text-field
                        dense
                        v-model.number="daysAY"
                        label="AY"
                        outlined
                        min="1"
                        type="number"
                      ></v-text-field>
                      <v-text-field
                        dense
                        v-model.number="daysAZ"
                        label="AZ"
                        outlined
                        min="1"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        v-model.number="daysBX"
                        label="BX"
                        outlined
                        min="1"
                        type="number"
                      ></v-text-field>
                      <v-text-field
                        dense
                        v-model.number="daysBY"
                        label="BY"
                        outlined
                        min="1"
                        type="number"
                      ></v-text-field>
                      <v-text-field
                        dense
                        v-model.number="daysBZ"
                        label="BZ"
                        outlined
                        min="1"
                        type="number"
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <v-switch v-model="order2" label="Order#2"></v-switch>
                      <p>Months : {{ m }}</p>
                      <p>
                        ETA:
                        {{
                          eta ? new Date(eta).toISOString().substr(0, 10) : ""
                        }}
                      </p>
                      <p>
                        Sezonowość:
                        {{ eta ? new Date(eta).getMonth() + 2 : "" }}-{{
                          eta ? new Date(eta).getMonth() + 4 : ""
                        }}
                      </p>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>

                  <v-divider></v-divider>
                  <toolfooter
                    :items="items"
                    :selected="selected"
                    :field.sync="field"
                    :defvendor="vendor"
                    :location="location.length ? location[0] : null"
                    :stockorder="true"
                    :lowstock="lowstock"
                    v-if="!loading"
                    :key="field"
                  />
                </div>
              </v-expand-transition>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import footer from "../backorderitems/footer";
import exportData from "../../dataTable/exportData";
import sort from "../../dataTable/sort";
export default {
  data() {
    return {
      loading: false,
      join: false,
      days: null,
      daysAX: null,
      daysAY: null,
      daysAZ: null,
      daysBX: null,
      daysBY: null,
      daysBZ: null,
      headers: [
        { text: "Item", value: "item" },
        { text: "To Build", value: "tobuild" },
        { text: "Pref. Stock", value: "preferedstocklevel" },
        { text: "Order Type", value: "ordertype" },
        { text: "Vendor", value: "customer" },
        { text: "To Order", value: "quantity" },
        { text: "MOQ", value: "moq" },
        { text: "MOQ For", value: "moqmonths" },
        { text: "Avg. Sales", value: "averagesales" },
        { text: "Available For", value: "availablemonths" },
        { text: "Available", value: "quantityavailable" },
        { text: "On Order", value: "quantityonorder" },
        { text: "BO", value: "quantitybackorder" },
        { text: "In Transit", value: "inTransit" },
        { text: "After ETA", value: "afterETA" },
        { text: "Open Orders", value: "openorders" },
        { text: "Manufacturer Stock", value: "manufacturerstock" },
        { text: "Stock Category", value: "stockcategory" },
        { text: "stockgroup", value: "stockgroup" },
        { text: "Weight", value: "weight" },
        { text: "Purchase Price", value: "purchaseprice" },
      ],
      headerssimple: [
        { text: "Price Group", value: "pricegroup" },
        { text: "Item", value: "item" },
        { text: "To Build", value: "tobuild" },
        { text: "Pref. Stock", value: "preferedstocklevel" },
        { text: "Manufacturer Stock", value: "manufacturerstock" },
        { text: "Order Type", value: "ordertype" },
        //{ text: "Vendor", value: "customer" },
        { text: "To Order", value: "quantity" },
        //{ text: "MOQ", value: "moq" },
        //{ text: "MOQ For", value: "moqmonths" },
        { text: "Avg. Sales", value: "averagesales" },
        //{ text: "Available For", value: "availablemonths" },
        { text: "Available", value: "quantityavailable" },
        { text: "On Order", value: "quantityonorder" },
        //{ text: "BO", value: "quantitybackorder" },
        { text: "In Transit", value: "inTransit" },
        //{ text: "After ETA", value: "afterETA" },
        { text: "Open Orders", value: "openorders" },
        //{ text: "Manufacturer Stock", value: "manufacturerstock" },
        { text: "Stock Category", value: "stockcategory" },
        { text: "Stock Group", value: "stockgroup" },
        //{ text: "Weight", value: "weight" },
        { text: "Purchase Price", value: "purchaseprice" },
        { text: "To Order #2", value: "quantity2" },
      ],
      items: [],
      rows: [-1],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      vendor: null,
      vendors: [],
      currency: null,
      currencies: [],
      message: "",
      countries: [],
      address: {},
      deliverymethod: null,
      deliverymethods: [],
      pricegroup: [],
      pricegroups: [],
      location: [],
      locations: [],
      quantity: 0,
      weight: 0,
      amount: 0,
      fleetorder: true,
      open: true,
      dropship: true,
      m: null,
      eta: null,
      field: "quantity",
      order2: false,
      simple: false,
      lowstock: false,
      beta: false,
      betaheaders: null,
    };
  },
  async created() {
    this.loading = true;
    this.vendors = await service("users", {
      action: "find",
      data: [
        {
          recordtype: { $in: ["vendor", "company"] },
          _id: {
            $in: [
              "maincompany",
              "ozpartsuklimited",
              "5e14abfb0398a160b00f57c0",
              "5e14abec0398a160b00f5641",
              "5e14abec0398a160b00f5631",
              "5e14ac010398a160b00f5875",
              "5e14abf00398a160b00f5697",
              "5e14abfb0398a160b00f57c5",
              "60bf520e4714f5fabb714409",
              // "60b4a0df16b70845f77186e1",
              "62f206a52de082a574e4597c",
              "67448c6e45d376ab5da39cda",
              "62f20b892de082a574e46173",
              "6229bc5ad1f8737e98912e13",
              "5e14abf00398a160b00f56a1",
              "65c79f887779a822b9f9351a",
            ],
          },
        },
      ],
    });
    this.deliverymethods = await service("lists", {
      action: "find",
      data: [{ recordtype: "deliverymethod" }],
    });
    this.locations = await service("accounting", {
      action: "find",
      data: [
        {
          recordtype: "location",
          _id: {
            $in: [
              "5e0dbaba9e33df43f0b3a495",
              "61e14c6ae4a35a668517a8fe",
              "62de6196fa2fc522dc692c7e",
              "66e04fcba16b2e1e7d873be8",
            ],
          },
        },
      ],
    });
    this.pricegroups = await service("accounting", {
      action: "find",
      data: [{ recordtype: "pricegroup" }],
    });
    await this.getData();

    this.loading = false;
  },
  watch: {
    order2(value) {
      this.field = value ? "quantity2" : "quantity";
    },
    simple(v) {
      if (v) this.lowstock = false;
    },
    lowstock(v) {
      if (v) this.simple = false;
    },
    beta(v) {
      if (v && this.vendor) {
        if (this.vendor._id == "5e14abfb0398a160b00f57c0")
          this.betaheaders = [
            { text: "Item", value: "item" },
            { text: "Available", value: "quantityavailable" },
            { text: "BO", value: "quantitybackorder" },
            { text: "PSL", value: "preferedstocklevel" },
            { text: "Alt. PSL", value: "preferedstocklevel2" },
            //{ text: "Sold (12M)", value: "sales" },
            { text: "Avg. Sales (12M)", value: "averagesales" },
            { text: "Avg. Sales (L3M)", value: "averagesaleslast3m" },
            { text: "Max. Sold", value: "colJ" },
            { text: "ile miesięcy dodać z LT", value: "colK" },
            { text: "Min Qty. z Lead Time", value: "colL" },
            { text: "No A/F", value: "custombox" },
            { text: "Small size", value: "smallsize" },
            { text: "Bulk QTy.", value: "bulkquantity" },
            //{ text: "In Transit", value: "inTransit" },
            { text: "On Order", value: "quantityonorder" },
            { text: "Open Orders", value: "openorders" },
            { text: "Man. Stock", value: "manufacturerstock" },
            { text: "Alt. Man. Stock", value: "manufacturerstock2" },
            { text: "Delivery Method", value: "deliverymethod" },
            { text: "Lead Time", value: "leadtime" },
            { text: "Demand LT", value: "colZ" },
            //{ text: "To order CN robocze LT", value: "colAC" },
            //{ text: "To ordr AU robocze LT", value: "colAD" },
            { text: "To order CN LT", value: "colAE" },
            { text: "To order AU LT", value: "colAF" },
            //{ text: "ile raZy To order CN LT", value: "colAG" },
            //{ text: "ile razy To order AU LT", value: "colAH" },
            { text: "To order CN", value: "quantity" },
            { text: "To order AU", value: "quantity2" },
          ];
        if (this.vendor._id == "5e14abec0398a160b00f5641") {
          this.betaheaders = [
            { text: "Item", value: "item" },
            { text: "Available", value: "quantityavailable" },
            { text: "BO", value: "quantitybackorder" },
            { text: "PSL", value: "preferedstocklevel" },
            { text: "Alt. PSL", value: "preferedstocklevel2" },
            //{ text: "Sold (12M)", value: "sales" },
            { text: "Avg. Sales (12M)", value: "averagesales" },
            { text: "Avg. Sales (L3M)", value: "averagesaleslast3m" },
            { text: "Max. Sold", value: "colJ" },
            { text: "ile miesięcy dodać z LT", value: "colK" },
            { text: "Min Qty. z Lead Time", value: "colL" },
            { text: "No A/F", value: "custombox" },
            //{ text: "Small size", value: "smallsize" },
            //{ text: "Bulk QTy.", value: "bulkquantity" },
            //{ text: "In Transit", value: "inTransit" },
            { text: "On Order", value: "quantityonorder" },
            { text: "Open Orders", value: "openorders" },
            { text: "Man. Stock", value: "manufacturerstock" },
            { text: "Alt. Man. Stock", value: "manufacturerstock2" },
            { text: "Delivery Method", value: "deliverymethod" },
            { text: "Lead Time", value: "leadtime" },
            { text: "Multiplier", value: "multiplier" },
            //{ text: "To order CN robocze LT", value: "colAC" },
            //{ text: "To ordr AU robocze LT", value: "colAD" },
            //{ text: "To order CN LT", value: "colAE" },
            //{ text: "To order AU LT", value: "colAF" },
            //{ text: "ile raZy To order CN LT", value: "colAG" },
            //{ text: "ile razy To order AU LT", value: "colAH" },
            { text: "To Order", value: "quantity" },
          ];
        }
      } else {
        this.betaheaders = null;
      }
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getPOAssistant",
          days: this.days,
          daysax: this.daysAX,
          daysay: this.daysAY,
          daysaz: this.daysAZ,
          daysbx: this.daysBX,
          daysby: this.daysBY,
          daysbz: this.daysBZ,
          vendor: this.vendor ? this.vendor._id : null,
          location: this.location,
          simple: this.simple,
          beta: this.beta,
          lowstock: this.lowstock,
          deliverymethod: this.deliverymethod ? this.deliverymethod._id : null,
          pricegroup: this.pricegroup,
          join: this.join,
        },
        false,
        true
      );
      this.items = result.items;
      if (
        this.vendor &&
        this.vendor._id == "5e14abf00398a160b00f5697" &&
        !this.deliverymethod
      ) {
        this.headers = [
          { text: "Item", value: "item" },
          { text: "Pref. Stock", value: "preferedstocklevel" },
          { text: "Order Type", value: "ordertype" },
          { text: "Vendor", value: "customer" },
          { text: "To Order", value: "quantity" },
          { text: "MOQ", value: "moq" },
          { text: "MOQ For", value: "moqmonths" },
          { text: "Avg. Sales", value: "averagesales" },
          { text: "Available For", value: "availablemonths" },
          { text: "Available", value: "quantityavailable" },
          { text: "On Order", value: "quantityonorder" },
          { text: "BO", value: "quantitybackorder" },
          { text: "In Transit", value: "inTransit" },
          { text: "After ETA", value: "afterETA" },
          { text: "Open Orders", value: "openorders" },
          { text: "Manufacturer Stock", value: "manufacturerstock" },
          { text: "Stock Category", value: "stockcategory" },
          { text: "Stock Group", value: "stockgroup" },
          { text: "Weight", value: "weight" },
          { text: "Sezonowsc", value: "sez" },
          { text: "After ETA#2", value: "afterETA2" },
          { text: "To Order #2", value: "quantity2" },
          { text: "Purchase Price", value: "purchaseprice" },
        ];
      }
      if (
        this.vendor &&
        (this.vendor._id == "5e14abec0398a160b00f5641" ||
          this.vendor._id == "5e14abf00398a160b00f5697")
      ) {
        this.headers = [
          { text: "Item", value: "item" },
          { text: "Available", value: "quantityavailable" },
          { text: "BO", value: "quantitybackorder" },
          { text: "Available Lasts Months", value: "availablemonths" },
          { text: "PSL", value: "preferedstocklevel" },
          { text: "Sold (12M)", value: "sales" },
          { text: "Tran. Count (12M)", value: "transactionscount" },
          { text: "Avg. Sales (12M)", value: "averagesales" },
          { text: "Avg. Peak Sales (12M)", value: "peakaveragesales" },
          { text: "Tran. Count (L3M)", value: "transactionscountlast3m" },
          { text: "Avg. Sales (L3M)", value: "averagesaleslast3m" },
          { text: "Avg. Peak Sales (L3M)", value: "peakaveragesaleslast3m" },
          { text: "MOQ", value: "moq" },
          { text: "MOQ Lasts Months", value: "moqmonths" },
          { text: "Min. Stock", value: "minstocklevel" },
          { text: "In Transit", value: "inTransit" },
          { text: "On Order", value: "quantityonorder" },
          { text: "Open Orders", value: "openorders" },
          { text: "Available + OnOrders for", value: "availableonorder" },
          { text: "To Order", value: "quantity" },
          { text: "Category", value: "stockcategory" },
          { text: "Group", value: "stockgroup" },
          { text: "Weight", value: "weight" },
          { text: "Purchase Price", value: "purchaseprice" },
          { text: "To Order #2", value: "quantity2" },
        ];
      }
      if (
        this.vendor &&
        (this.vendor._id == "5e14abfb0398a160b00f57c0" ||
          this.vendor._id == "6229bc5ad1f8737e98912e13")
      ) {
        this.headers = [
          { text: "Item", value: "item" },
          { text: "Available", value: "quantityavailable" },
          { text: "BO", value: "quantitybackorder" },
          { text: "Available Lasts Months", value: "availablemonths" },
          { text: "PSL", value: "preferedstocklevel" },
          { text: "Alt. PSL", value: "preferedstocklevel2" },
          { text: "Sold (12M)", value: "sales" },
          { text: "Tran. Count (12M)", value: "transactionscount" },
          { text: "Avg. Sales (12M)", value: "averagesales" },
          { text: "Avg. Peak Sales (12M)", value: "peakaveragesales" },
          { text: "Tran. Count (L4M)", value: "transactionscountlast4m" },
          { text: "Avg. Sales (L4M)", value: "averagesaleslast4m" },
          //{ text: "Avg. Peak Sales (L4M)", value: "peakaveragesaleslast4m" },
          { text: "MOQ", value: "moq" },
          { text: "MOQ Lasts Months", value: "moqmonths" },
          //{ text: "Min. Stock", value: "minstocklevel" },
          { text: "In Transit", value: "inTransit" },
          { text: "On Order", value: "quantityonorder" },
          { text: "Open Orders", value: "openorders" },
          { text: "Available + OnOrders for", value: "availableonorder" },
          { text: "To Order", value: "quantity" },
          { text: "Category", value: "stockcategory" },
          { text: "Group", value: "stockgroup" },
          { text: "Weight", value: "weight" },
          { text: "Purchase Price", value: "purchaseprice" },
          { text: "Manufacturer Stock", value: "manufacturerstock" },
          { text: "Alt. Manufacturer Stock", value: "manufacturerstock2" },
        ];
      }
      if (result.destination && !this.lowstock) {
        this.headerssimple = [
          { text: "Price Group", value: "pricegroup" },
          { text: "Item", value: "item" },
          { text: "To Build", value: "tobuild" },
          { text: "Pref. Stock", value: "preferedstocklevel" },
          { text: "Order Type", value: "ordertype" },
          //{ text: "Vendor", value: "customer" },
          { text: "To Order", value: "quantity" },
          //{ text: "MOQ", value: "moq" },
          //{ text: "MOQ For", value: "moqmonths" },
          { text: "Avg. Sales", value: "averagesales" },
          //{ text: "Available For", value: "availablemonths" },
          { text: "Available", value: "quantityavailable" },
          { text: "On Order", value: "quantityonorder" },
          //{ text: "BO", value: "quantitybackorder" },
          { text: "In Transit", value: "inTransit" },
          //{ text: "After ETA", value: "afterETA" },
          { text: "Open Orders", value: "openorders" },
          //{ text: "Manufacturer Stock", value: "manufacturerstock" },
          { text: "Stock Category", value: "stockcategory" },
          { text: "Stock Group", value: "stockgroup" },
          //{ text: "Weight", value: "weight" },
          { text: "Purchase Price", value: "purchaseprice" },
        ];
        this.headerssimple.push({
          text: "PL Available",
          value: "destinationquantityavailable",
        });
        this.headerssimple.push({
          text: "PL On Order ",
          value: "destinationquantityonorder",
        });
        this.headerssimple.push({
          text: "PL BO ",
          value: "destinationquantitybackorder",
        });
        this.headerssimple.push({
          text: "PL Category",
          value: "destinationstockcategory",
        });
        this.headerssimple.push({
          text: "PL Group",
          value: "destinationstockgroup",
        });
      }
      this.m = result.m;
      this.eta = result.eta;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
  },
  components: {
    toolfooter: footer,
    exportData: exportData,
  },
};
</script>
<style scope>
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 8px;
}
</style>