var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('vuevectormap',{key:_vm.loading,ref:"map",staticClass:"ma-2",attrs:{"map":"world","width":"750px","height":"400px","regionsSelectable":true,"regionsSelectableOne":true,"selectedRegions":_vm.selectedRegions,"series":_vm.series},on:{"regionSelected":_vm.test}})],1),_c('v-col',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',[_c('v-autocomplete',{staticClass:"mt-2 mb-2",attrs:{"items":_vm.countries,"item-text":"name","item-value":"_id","label":"Country","outlined":"","clearable":"","hide-details":"","dense":""},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('v-text-field',{staticClass:"mt-2 mb-2",attrs:{"label":"Zip Code","disabled":true,"readonly":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.zip),callback:function ($$v) {_vm.zip=$$v},expression:"zip"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"mt-2 mb-2",attrs:{"label":"Weight","disabled":!_vm.country,"outlined":"","dense":"","hide-details":"","clearable":""},on:{"change":_vm.calcWeight},model:{value:(_vm.weight),callback:function ($$v) {_vm.weight=$$v},expression:"weight"}})],1)],1),_c('v-row',[_c('v-col',[(_vm.country)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredMethods,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"custom-sort":_vm.sort,"single-select":_vm.singleSelect,"group-by":_vm.group},scopedSlots:_vm._u([{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.location.displayname || item.location.name))])]}},{key:"item.shippingcarrier",fn:function(ref){
var item = ref.item;
return [(item.shippingcarrier)?_c('v-row',[(item.logo)?_c('v-col',[_c('v-img',{attrs:{"max-height":"24","max-width":"24","src":item.logo.url}})],1):_c('v-col',[_c('span',[_vm._v(_vm._s(item.shippingcarrier.name))])])],1):_vm._e()]}},{key:"item.transaction",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
                  path: ("/" + (item.transaction.collection) + "/" + (item.transaction.recordtype) + "/edit/" + (item.transaction._id)),
                }}},[_vm._v(_vm._s(item.transaction.displayname || item.transaction.name))])]}},{key:"item.shipmethod",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.shipmethod ? item.shipmethod.name : ""))])]}},{key:"item.statusref",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.statusref ? item.statusref.name : ""))])]}}],null,false,1460356829),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }