 <template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active1">
          <v-tab
            v-for="(tablocation, indexlocation) of tabslocation.filter(
              (s) => !s.hidden
            )"
            :key="indexlocation"
            >{{ tablocation.name }}</v-tab
          >
          <v-tab-item
            v-for="(tablocation, indexlocation) of tabslocation.filter(
              (s) => !s.hidden
            )"
            :key="indexlocation"
          >
            <v-tabs dark v-model="active2">
              <v-tab v-for="(tab, index) of tabs" :key="index">{{
                tab.name
              }}</v-tab>
              <v-tab-item v-for="(tab, index) of tabs" :key="index">
                <v-data-table
                  :headers="tab.headers"
                  :items="
                    tab.items.filter((item) => {
                      return tablocation.location.includes(item.location);
                    })
                  "
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  item-key="_id"
                  v-model="selected"
                  :show-select="show"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :single-select="singleSelect"
                  :footer-props="{ itemsPerPageOptions: rows }"
                  :custom-filter="filter"
                  @current-items="getFiltered($event, tab, indexlocation)"
                  :id="`eta-assistent-${indexlocation}-${index}`"
                  :item-class="rowClass"
                >
                  <template v-slot:item.date="{ item }">
                    <span>{{
                      new Date(item.date).toISOString().substr(0, 10)
                    }}</span>
                  </template>
                  <template v-slot:item.eta="{ item }">
                    <v-menu
                      v-model="item.menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      :disabled="item.delivery ? true : false"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          label="ETA"
                          placeholder
                          v-model="item.eta"
                          append-icon="event"
                          readonly
                          v-on="on"
                          single-line
                          dense
                          class="mt-2 mb-2"
                          clearable
                          @click:clear="action(item, 'eta', null)"
                          @change="action(item)"
                          persistent-hint
                          :hint="item.delivery ? item.delivery.name : ''"
                        ></v-text-field>
                      </template>
                      <v-card>
                        <v-date-picker
                          v-model="item.eta"
                          :allowed-dates="
                            (date) => allowedDates(date, item.delivery)
                          "
                          :events="
                            (date) => allowedEvents(date, item.deliveries)
                          "
                          @click:date="(date) => dateClick(date, item)"
                          :first-day-of-week="1"
                        >
                        </v-date-picker>
                        <v-divider></v-divider>
                        <v-select
                          dense
                          class="mx-4 py-4"
                          item-text="displayname"
                          item-value="_id"
                          :items="item.deliveries"
                          label="Delivery"
                          @change="setDelivery(item)"
                          return-object
                          v-model="item.delivery"
                          :hint="item.delivery ? item.delivery.displayname : ''"
                          persistent-hint
                          clearable
                          @click:clear="setDelivery(item)"
                        >
                          <template v-slot:selection="{ item }">
                            {{ item.name }}
                          </template>

                          <template v-slot:item="{ item: del }">
                            <v-list-item-title>
                              {{ del.displayname }} ({{
                                item.fleetorder
                                  ? del["fleetquantity"]
                                  : del["quantity"]
                              }})</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{ del.deliverymethod }}</v-list-item-subtitle
                            >
                          </template>
                        </v-select>
                      </v-card>
                    </v-menu>
                  </template>
                  <template v-slot:item.transaction="{ item }">
                    <router-link
                      :to="{
                        path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
                      }"
                      class="link"
                      >{{
                        item.transaction.displayname || item.transaction.name
                      }}</router-link
                    >
                    <br />
                    <!-- <small @click="setFilter(item.transaction.name,'transaction')">Filter</small><small @click="resetFilter('transaction')">Reset</small> -->
                  </template>
                  <template v-slot:item.customer="{ item }">
                    <router-link
                      :to="{
                        path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                      }"
                      class="link"
                      >{{
                        item.customer.displayname || item.customer.name
                      }}</router-link
                    >
                    <br />
                    <small @click="setFilter(item.customer, 'customer')"
                      >Filter</small
                    >
                    |
                    <small @click="resetFilter('customer')">Reset</small>
                  </template>
                  <template v-slot:item.item="{ item }">
                    <router-link
                      :to="{
                        path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
                      }"
                      class="link"
                      >{{
                        item.item.displayname || item.item.name
                      }}</router-link
                    >
                    <br />
                    <small @click="setFilter(item.item, 'item')">Filter</small>
                    |
                    <small @click="resetFilter('item')">Reset</small>
                    <br /><small>{{ item.item.manufacturergroup }}</small>
                  </template>
                  <template v-slot:item.fleetorder="{ item }">
                    <v-chip
                      label
                      :color="item.fleetorder ? 'red' : 'none'"
                      dark
                      >{{ item.fleetorder ? "Yes" : "No" }}</v-chip
                    >
                  </template>
                  <template v-slot:item.dropship="{ item }">
                    <v-chip
                      label
                      :color="item.dropship ? 'red' : 'none'"
                      dark
                      >{{ item.dropship ? "Yes" : "No" }}</v-chip
                    >
                  </template>
                  <template v-slot:item.additionalservice="{ item }">
                    <!-- <span>{{ item.memo }}</span> -->
                    <span>{{ item.additionalservice }}</span>
                  </template>
                  <!-- <template v-slot:item.delivery="{ item }">
                    <v-select
                      :items="deliveries"
                      v-model="item.delivery"
                      label="Delivery"
                      item-text="displayname"
                      item-value="_id"
                      return-object
                      outlined
                      dense
                      hide-details
                      class="mt-2 mb-2"
                      clearable
                      @click:clear="setDelivery(item)"
                      @change="setDelivery(item)"
                    ></v-select>
                  </template> -->
                  <template v-slot:item.deliveries="{ item }">
                    <span
                      v-for="po in item.deliveries"
                      :key="po._id"
                      :class="[po.deliverymethod, po.ordertype]"
                    >
                      {{ po.deliverymethod }} : {{ po.displayname }} :
                      {{
                        item.fleetorder ? po["fleetquantity"] : po["quantity"]
                      }}
                      <br />
                    </span>

                    <small
                      @click="
                        setFilter(
                          item.item.manufacturergroup,
                          'manufacturergroup'
                        )
                      "
                      >Filter</small
                    >
                    |
                    <small @click="resetFilter('manufacturergroup')"
                      >Reset</small
                    >
                  </template>

                  <template v-slot:item.etamemo="{ item }">
                    <v-combobox
                      type="text"
                      dense
                      v-model="item.etamemo"
                      label="ETA Memo"
                      single-line
                      hide-details
                      class="mt-2 mb-2"
                      @click:clear="action(item)"
                      @change="action(item)"
                      :items="etamemos"
                    ></v-combobox>
                  </template>
                  <template v-slot:top>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <v-select
                            dense
                            v-model="manufacturer"
                            :items="manufacturergroups"
                            label="Manufacturer"
                            outlined
                            item-text="name"
                            item-value="_id"
                            hide-details
                            clearable
                            @change="getData()"
                          ></v-select>
                        </v-col>
                        <v-col>
                          <v-combobox
                            dense
                            v-model="item"
                            :items="filteritems"
                            label="Item"
                            outlined
                            return-object
                            item-text="name"
                            item-value="_id"
                            hide-details
                            clearable
                            @keyup.enter="searchRecords('item')"
                          ></v-combobox>
                        </v-col>
                        <v-col>
                          <v-combobox
                            dense
                            v-model="customer"
                            :items="customers"
                            label="Customer"
                            outlined
                            return-object
                            item-text="name"
                            item-value="_id"
                            hide-details
                            clearable
                            @keyup.enter="searchRecords('customer')"
                          ></v-combobox>
                        </v-col>

                        <v-col>
                          <v-menu
                            v-model="tab.menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                label="ETA"
                                placeholder
                                v-model="eta"
                                append-icon="event"
                                readonly
                                v-on="on"
                                outlined
                                dense
                                hide-details
                                clearable
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-if="indexlocation == active1"
                              v-model="eta"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col>
                          <v-autocomplete
                            :items="deliveries"
                            v-model="delivery"
                            label="Delivery"
                            outlined
                            dense
                            return-object
                            item-text="displayname"
                            item-value="_id"
                            hide-details
                            clearable
                          ></v-autocomplete>
                        </v-col>
                        <!-- <v-col>
                      <v-switch v-model="fleetorder" label="Fleet Order"></v-switch>
                    </v-col>
                    <v-col>
                      <v-switch v-model="dropship" label="Drop Ship"></v-switch>
                    </v-col>-->
                        <v-col>
                          <v-btn
                            @click="resetFilter()"
                            block
                            tile
                            color="primary"
                            >Reset</v-btn
                          >
                        </v-col>
                        <v-col>
                          <v-pagination
                            v-model="page"
                            :length="pageCount"
                            :total-visible="0"
                          ></v-pagination>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col class="text-right">
                          <exportData
                            :headers="hedersExport"
                            :items="tab.filteredItems"
                          />
                          <v-btn icon @click="show = !show">
                            <v-icon>{{
                              show ? "mdi-chevron-up" : "mdi-chevron-down"
                            }}</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-expand-transition>
                        <div
                          v-show="show"
                          v-if="active1 == indexlocation && active2 == index"
                        >
                          <v-divider></v-divider>
                          <v-row>
                            <v-col>
                              <v-combobox
                                v-model="params.vendor"
                                :items="vendors"
                                label="Vendor"
                                outlined
                                dense
                              ></v-combobox>
                            </v-col>
                            <v-col>
                              <v-menu
                                v-model="newetamenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    label="New ETA for Selected"
                                    placeholder
                                    v-model="params.eta"
                                    append-icon="event"
                                    readonly
                                    v-on="on"
                                    outlined
                                    dense
                                    hide-details
                                    clearable
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-if="indexlocation == active1"
                                  v-model="params.eta"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col>
                              <v-btn
                                @click="action(null, 'eta', params.eta)"
                                block
                                tile
                                color="primary"
                                >Set New ETA ({{ selected.length }})</v-btn
                              >
                            </v-col>

                            <v-col></v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-data-table
                                :headers="customersHeader"
                                :items="customersItems"
                                :loading="loading"
                                loading-text="Loading... Please wait"
                                item-key="_id"
                                v-model="customersSelected"
                                :show-select="true"
                                :single-select="singleSelect"
                                :footer-props="{ itemsPerPageOptions: rows }"
                              ></v-data-table>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-overflow-btn
                                style="min-width=250px"
                                flat
                                class="mt-5"
                                :items="quickemails"
                                label="Quick Email"
                                segmented
                              ></v-overflow-btn>
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                        </div>
                      </v-expand-transition>
                    </v-container>
                  </template>
                  <template slot="body.append">
                    <tr v-if="active1 == indexlocation">
                      <th v-for="(col, index) in tab.headers" :key="index">
                        <span :key="tab.totalbo"
                          >{{
                            col.value == "quantitybackorder" ? tab.totalbo : ""
                          }}
                        </span>
                      </th>
                    </tr>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
import { sendEmail } from "../../../common/methods/email";
export default {
  data() {
    return {
      loading: false,
      totalbo: 0,
      locationsFilter: [],
      tabslocation: [
        { name: "PL Warehouse", location: ["5e0dbaba9e33df43f0b3a495"] },
        { name: "NL Warehouse", location: ["66e04fcba16b2e1e7d873be8"] },
        {
          name: "UK Warehouse (Birmingham)",
          location: ["5e0dbaba9e33df43f0b3a496", "5faa7485371aa739b8a08843"],
          hidden: true,
        },
        {
          name: "UK Warehouse",
          location: ["61e14c6ae4a35a668517a8fe"],
        },
        {
          name: "Brakes PL Warehouse",
          location: ["62de6196fa2fc522dc692c7e"],
        },
      ],
      etamemos: ["currently no ETA", "chasing ETA", "delay", "partly"],
      tabs: [
        {
          name: "Stock",
          items: [],
          field: "quantity",
          filteredItems: [],
          menu: false,
          totalbo: 0,
          headers: [
            //{ text: "Location", value: "location" },
            { text: "Sales Order", value: "transaction", sortable: false },
            { text: "Date", value: "date", width: "100px" },
            { text: "Customer", value: "customer", sortable: false },
            { text: "Item", value: "item", sortable: false },
            { text: "BO", value: "quantitybackorder" },
            { text: "ETA", value: "eta", width: "170px" },
            { text: "ETA Memo", value: "etamemo", minwidth: "120px" },
            { text: "Serivce", value: "additionalservice" },
            { text: "PO", value: "correlated" },
            {
              text: "Deliveries",
              value: "deliveries",
              sortable: false,
              width: "280px",
            },
            { text: "Ship Date", value: "planneddateofshipment" },
          ],
        },
        {
          name: "Special",
          items: [],
          field: "fleetquantity",
          filteredItems: [],
          menu: false,
          totalbo: 0,
          headers: [
            { text: "Fleet Order", value: "fleetorder", width: "30px" },
            { text: "Dropship", value: "dropship", width: "30px" },
            { text: "Sales Order", value: "transaction", sortable: false },
            { text: "Date", value: "date", width: "100px" },
            { text: "Customer", value: "customer", sortable: false },
            { text: "Item", value: "item", sortable: false, width: "100px" },
            { text: "BO", value: "quantitybackorder" },
            { text: "ETA", value: "eta", width: "170px" },
            { text: "ETA Memo", value: "etamemo", minwidth: "120px" },
            { text: "Serivce", value: "additionalservice" },
            { text: "PO", value: "correlated" },
            {
              text: "Deliveries",
              value: "deliveries",
              sortable: false,
              width: "290px",
            },
            { text: "Ship Date", value: "planneddateofshipment" },
          ],
        },
        {
          name: "Stock request",
          items: [],
          field: "quantity",
          filteredItems: [],
          menu: false,
          totalbo: 0,
          headers: [
            { text: "Fleet Order", value: "fleetorder", width: "30px" },
            { text: "Dropship", value: "dropship", width: "30px" },
            { text: "Sales Order", value: "transaction", sortable: false },
            { text: "Date", value: "date", width: "100px" },
            { text: "Customer", value: "customer", sortable: false },
            { text: "Item", value: "item", sortable: false, width: "100px" },
            { text: "BO", value: "quantitybackorder" },
            { text: "ETA", value: "eta", width: "170px" },
            { text: "ETA Memo", value: "etamemo", minwidth: "120px" },
            { text: "Serivce", value: "additionalservice" },
            { text: "PO", value: "correlated" },
            {
              text: "Deliveries",
              value: "deliveries",
              sortable: false,
              width: "280px",
            },
            { text: "Ship Date", value: "planneddateofshipment" },
          ],
        },
      ],
      hedersExport: [
        { text: "Sales Order", value: "transaction", sortable: false },
        { text: "Date", value: "date" },
        { text: "Customer", value: "customer", sortable: false },
        { text: "Item", value: "item", sortable: false, width: "150px" },
        { text: "BO", value: "quantitybackorder" },
        { text: "ETA", value: "eta" },
        { text: "ETA Memo", value: "etamemo" },
        { text: "Serivce", value: "additionalservice" },
      ],
      customersItems: [],
      customersSelected: [],
      customersHeader: [
        { text: "Receiver", value: "name", sortable: false },
        { text: "Source", value: "source", sortable: false },
        { text: "Country", value: "billcountry", sortable: false },
      ],
      active1: 0,
      active2: 0,
      items: [],
      lastpage: 1,
      page: 1,
      pageCount: 0,
      rows: [100],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      customer: null,
      customers: [],
      manufacturer: null,
      manufacturergroup: null,
      manufacturergroups: [],
      filteritems: [],
      delivery: null,
      deliveries: [],
      item: null,
      eta: null,
      newetamenu: null,
      //neweta: null,
      quickemails: [],
      fleetorder: true,
      dropship: true,
      params: {
        vendor: null,
        eta: null,
      },
      vendors: [
        "Aeroflow",
        "ACL",
        "ACS",
        "DBA",
        "Hawk",
        "Pedders",
        "Redarc",
        "Saber",
        "Turbosmart",
        "Whiteline/Nolathane",
        "Xforce",
      ],
    };
  },
  async created() {
    this.loading = true;
    if (this.$store.state.user.location)
      this.locationsFilter.push(this.$store.state.user.location);
    if (
      this.$store.state.user.forlocations &&
      this.$store.state.user.forlocations.length
    )
      this.locationsFilter.push(...this.$store.state.user.forlocations);

    this.tabslocation = this.tabslocation.filter(
      (loc) =>
        this.locationsFilter.some((l) => loc.location.includes(l)) ||
        !this.locationsFilter.length
    );
    this.manufacturergroups = await service(
      "classification",
      {
        action: "find",
        data: [{ recordtype: "manufacturergroup" }],
      },
      false,
      true
    );
    // if (this.manufacturergroups.length)
    //   this.manufacturer = this.manufacturergroups[0]._id;
    await this.getData();
    this.deliveries = await service(
      "deliveries",
      {
        action: "find",
        data: [
          {
            eta: { $exists: true, $ne: null },
            recordtype: "delivery",
            hidden: { $ne: true },
            status: { $nin: ["received", "pendingapproval", "picek"] },
          },
        ],
        sort: { eta: 1 },
      },
      false,
      true
    ).then((response) => {
      response.forEach((v) => {
        v.displayname = v.displayname || v.name;
        if (v.eta)
          v.displayname =
            ` ${new Date(v.eta).toISOString().substr(0, 10)} - ` +
            v.displayname;
      });
      return response;
    });
    let templates = await service(
      "emails",
      {
        action: "find",
        data: [
          {
            recordtype: "emailtemplate",
            //hidden: { $ne: true },
            _id: {
              $in: [
                "5ecb9a4117afbb3808abf4d1",
                "5f9919f81a1aee67e4d34df1",
                "634f91b827ecf92d86b688b7",
              ],
            },
            type: { $in: ["customer"] },
          },
        ],
      },
      false,
      true
    );
    this.quickemails = [];
    templates.map((template) => {
      this.quickemails.push({
        text: template.name,
        callback: () => {
          var response = confirm("Send Email - Are you sure?");
          if (response && this.params.vendor) {
            this.params.transactionlines = this.selected || [];
            this.customersSelected.forEach((receipt) => {
              let email = {
                emailtemplate: template._id,
                params: this.params,
              };
              email.record = receipt._id;
              email.collection = receipt.collection;
              email.recordtype = receipt.recordtype;
              sendEmail(this, email);
            });

            alert("Emails was sent");
          } else {
            if (!this.params.vendor) alert("Vendor is required");
            if (!this.params.eta) alert("ETA is required");
          }
        },
      });
    });

    this.services = await service(
      "lists",
      {
        action: "find",
        data: [{ recordtype: "additionalservice" }],
      },
      false,
      true
    );

    this.loading = false;
    // let rows = document.querySelectorAll("#eta-assistent tbody tr");
    // this.items.forEach((item, index) => {
    //   if ((item.fleetorder || item.dropship) && rows[index]) {
    //     rows[index].classList.add("specialorder");
    //   }
    //   if (item.eta && new Date(item.eta) < new Date()) {
    //     rows[index].classList.add("overdue");
    //   }
    // });
    this.search = "NO ETA";
  },
  watch: {
    show(value) {
      this.rows = value ? [-1] : [100];
    },
    eta(value) {
      this.search = value;
    },
    item(value) {
      this.search = value ? value._id : null;
    },
    customer(value) {
      this.search = value ? value._id : null;
    },
    manufacturergroup(value) {
      this.search = value ? value : null;
    },
    search(value) {
      if (!value) this.search = "NO ETA";
      //this.rows = [this.search ? -1 : 0];
    },
  },
  methods: {
    allowedDates(val, delivery) {
      if (delivery) {
        return !!(val == new Date(delivery.eta).toISOString().substr(0, 10));
      } else return true;
    },

    allowedEvents(val, deliveries) {
      if (deliveries) {
        let event = deliveries.find(
          (del) => new Date(del.eta).toISOString().substring(0, 10) == val
        );
        if (event) return true;
      }
      return false;
    },
    dateClick(val, item) {
      if (item.deliveries) {
        let event = item.deliveries.find(
          (del) => new Date(del.eta).toISOString().substring(0, 10) == val
        );
        if (event) {
          item.delivery = event;
          this.setDelivery(item);
        } else this.action(item);
      } else this.action(item);
    },

    rowClass(item) {
      if (item.eta && new Date(item.eta) < new Date()) {
        return "overdue";
      }
    },
    getFiltered(e, tab, index) {
      tab.filteredItems = e;
      if (this.active1 == index) {
        tab.totalbo = tab.filteredItems.reduce((total, value) => {
          return total + value["quantitybackorder"];
        }, 0);
        this.totalbo = tab.totalbo;
      }
    },
    async searchRecords(field) {
      this.loading = true;
      await this.getItems(field);
      this.loading = false;
    },
    async getItems(field) {
      if (field == "item" && this.item && !this.item._id) {
        this.filteritems = await service(
          "items",
          {
            action: "find",
            data: [
              {
                name: { $regex: `.*${this.item}.*`, $options: "i" },
                recordtype: { $in: ["inventoryitem", "kititem"] },
              },
            ],
          },
          false,
          true
        );
      }
      if (field == "customer" && this.customer && !this.customer._id) {
        this.customers = await service(
          "users",
          {
            action: "find",
            data: [
              {
                name: { $regex: `.*${this.customer}.*`, $options: "i" },
                recordtype: {
                  $in: ["customer", "company"],
                },
                status: { $ne: "lead" },
              },
            ],
          },
          false,
          true
        );
      }
    },

    async setFilter(value, field) {
      this.lastpage = this.page;
      this[field] = value;
    },
    resetFilter(field) {
      this.items.forEach((item) => {
        item.oldETA = item.eta;
      });
      this.search = "NO  ETA";
      this.search = "NO ETA";
      if (field) {
        this[field] = null;
      } else {
        this.eta = null;
        this.item = null;
        this.customer = null;
        this.manufacturergroup = null;
      }
      this.page = this.lastpage;
    },
    async action(item, field, value) {
      let lines = [];
      if (item) {
        item[field] = value;
        if (field == "eta" && !value && item.delivery) item.delivery = null;
        lines = [
          {
            _id: item._id,
            eta: item.eta,
            delivery: item.delivery ? item.delivery._id || item.delivery : null,
            etamemo: item.etamemo,
          },
        ];
        if (
          this.customersItems.every(
            (customer) => customer._id != item.customer._id
          )
        ) {
          this.customersItems.push(item.customer);
          if (!item.customer.isperson) {
            item.customer.billcountry = item.transaction.billcountry;
            this.customersSelected.push(item.customer);
          }
        }
      } else {
        lines = this.selected.map((item) => {
          item[field] = value;
          if (
            this.customersItems.every(
              (customer) => customer._id != item.customer._id
            )
          ) {
            this.customersItems.push(item.customer);
            if (!item.customer.isperson) {
              item.customer.billcountry = item.transaction.billcountry;
              this.customersSelected.push(item.customer);
            }
          }
          return {
            _id: item._id,
            eta: item.eta,
            delivery: item.delivery,
            etamemo: item.etamemo,
            //additionalservice: item.additionalservice
          };
        });
      }
      //console.log(lines);
      let result = await service("orders", {
        action: "updateETA",
        lines: lines,
      });
    },
    setDelivery(item) {
      if (item.delivery)
        item.eta = new Date(item.delivery.eta).toISOString().substr(0, 10);
      this.action(item);
    },
    async getData() {
      this.loading = true;
      this.tabs.forEach((tab) => {
        tab.items = [];
      });
      let result = await service(
        "orders",
        {
          action: "getEstimatedTimeOfArrival",
          item: this.item ? this.item._id : null,
          customer: this.customer ? this.customer._id : null,
          delivery: this.delivery ? this.delivery._id : null,
          manufacturergroup: this.manufacturer,
          // date: this.date
        },
        false,
        true
      );
      this.items = result.items;
      //let customers = {};
      result.items.forEach((item) => {
        item.oldETA = item.eta;
        //customers[item.customer._id] = item.customer;
        if (
          (item.customer.recordtype == "company" && item.fleetorder) ||
          item.parentrecordtype == "stockrequest"
        ) {
          this.tabs[2].items.push(item);
          this.tabs[2].filteredItems.push(item);
        } else {
          if (!item.fleetorder && !item.dropship) {
            this.tabs[0].items.push(item);
            this.tabs[0].filteredItems.push(item);
          } else {
            this.tabs[1].items.push(item);
            this.tabs[1].filteredItems.push(item);
          }
        }
      });
      this.loading = false;
      //this.customersItems = Object.values(customers);
      //console.log(this.customersItems);
    },
    filter(value, search, item) {
      if (
        this.eta ||
        this.item ||
        this.customer ||
        this.manufacturergroup ||
        this.delivery
      ) {
        if (
          ((this.eta &&
            new Date(item.eta).toISOString().substr(0, 10) ==
              new Date(this.eta).toISOString().substr(0, 10)) ||
            new Date(item.oldETA).toISOString().substr(0, 10) ==
              new Date(this.eta).toISOString().substr(0, 10) ||
            !this.eta) &&
          ((this.customer && this.customer._id == item.customer._id) ||
            !this.customer) &&
          ((this.item && this.item._id == item.item._id) || !this.item) &&
          ((this.manufacturergroup &&
            this.manufacturergroup == item.item.manufacturergroup) ||
            !this.manufacturergroup) &&
          ((this.delivery &&
            item.delivery &&
            this.delivery._id == item.delivery._id) ||
            !this.delivery)
        )
          return true;
        else return false;
      } else {
        if (search == "NO ETA") {
          if (
            ((new Date(item.eta) > new Date() && item.oldETA == item.eta) ||
              (!this.dropship && item.dropship) ||
              (!this.fleetorder && item.fleetorder)) &&
            !this.delivery
          ) {
            return false;
          } else return true;
        } else return filter(value, search, item);
      }
    },
  },
  components: {
    exportData,
  },
};
</script>
<style>
.AIR.AU {
  color: red;
  font-weight: bold;
}
.Air.Freight.AUS-PL {
  color: red;
  font-weight: bold;
}
.specialorder {
  background: #b5b5b5;
}
.overdue {
  background: #f9ffa3 !important;
}
</style>
