<template>
  <v-row>
    <v-col cols="5">
      <v-container fluid>
        <v-row v-if="!redarcitem">
          <v-col class="my-0 py-0 mb-2">
            <v-text-field
              v-model="scan"
              append-icon="search"
              label="Search by Barcode"
              v-on:keyup.enter="decodeBarcode($event, scan)"
              single-line
              hide-details
              outlined
              v-if="mode == 'barcode'"
              append-outer-icon="mdi-barcode-scan"
              @click:append-outer="mode = 'name'"
            ></v-text-field>
            <v-combobox
              v-else
              v-model="scan"
              item-text="name"
              item-value="name"
              append-icon="search"
              label="Search by Item Name"
              :items="items"
              return-object
              outlined
              v-on:keyup.enter="getItems(scan)"
              @change="decodeBarcode(scan)"
              hint="type item name and put enter to find"
              append-outer-icon="mdi-keyboard"
              @click:append-outer="mode = 'barcode'"
            >
              <template slot="prepend-item">
                <v-text-field
                  single-line
                  hide-details
                  outlined
                  label="Quantity"
                  prefix="Quantity:"
                  v-model="qty"
                >
                </v-text-field>
              </template>
              <template slot="item" slot-scope="data">
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  <v-list-item-subtitle
                    >{{ data.item.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row
          v-if="
            mode != 'name' &&
            ((record.customer &&
              record.customer._id == '5e14abfd0398a160b00f580f') ||
              includeredarc)
          "
        >
          <v-col cols="8" class="my-0 py-0">
            <v-combobox
              :dense="!redarcitem"
              v-model="redarcitem"
              :items="redarcitems"
              label="Redarc Item"
              outlined
              return-object
              item-text="name"
              clearable
              item-value="_id"
              @focus="searchRecords"
              @change="lastBox"
            ></v-combobox>
          </v-col>

          <v-col class="my-0 py-0">
            <v-text-field
              v-if="redarcitem"
              :dense="!redarcitem"
              :loading="loading"
              type="number"
              single-line
              hide-details
              outlined
              label="Box"
              prefix="Box:"
              v-model="redarcbox"
              append-outer-icon="mdi-view-list"
              @click:append-outer="
                redarcbox ? (redarcboxdialog = true) : (redarcboxdialog = false)
              "
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-chip v-if="duplicates" label color="red" style="color: white">
          Labels includes duplicates!</v-chip
        >

        <v-dialog v-model="redarcboxdialog" max-width="450">
          <v-card v-if="redarcitem">
            <v-card-title v-if="!this.record['deliveryrecords']"
              >{{ redarcitem.name }}
            </v-card-title>
            <v-text-field
              v-else
              class="ma-2 mx-4 pt-2"
              v-model="scan"
              append-icon="search"
              label="Scan Serial No."
              v-on:keyup.enter="decodeBarcode($event, scan)"
              single-line
              hide-details
              outlined
              append-outer-icon="mdi-barcode-scan"
            ></v-text-field>

            <v-list subheader flat dense>
              <v-subheader>Box {{ redarcbox }} includes:</v-subheader>

              <v-list-item-group v-model="redarcselected" multiple>
                <v-list-item v-for="(item, index) of redarcinbox" :key="index">
                  <template v-slot:default="{ active }">
                    <v-list-item-action class="ma-1">
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                      ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title
                        ><v-chip
                          label
                          color="red"
                          class="mr-2"
                          v-if="
                            redarcinbox.find(
                              (d, index2) =>
                                d.value == item.value && index2 != index
                            )
                          "
                        >
                          Duplicate</v-chip
                        >
                        <v-chip
                          label
                          color="yellow"
                          class="mr-2"
                          v-if="item.value.length < 14"
                        >
                          Incomplete Scan?</v-chip
                        >
                        {{ item.value }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        <small v-if="item.created" label color="green">
                          In:{{
                            item.created
                              ? new Date(item.created)
                                  .toISOString()
                                  .substr(0, 10)
                              : ""
                          }}</small
                        >
                        <small v-if="item.out" class="ml-2" color="red"
                          >/ Out:{{
                            item.out
                              ? new Date(item.out).toISOString().substr(0, 10)
                              : ""
                          }}</small
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="redarcboxdialog = false">
                Cancel
              </v-btn>

              <v-btn color="primary" tile @click="addRedarcLabels()">
                Add ({{ redarcselected.length }})
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-col>
    <v-col v-if="scans.length">
      <small class="text-caption">LAST SCANS:</small>
      <small class="text-caption">
        <li v-for="(scan, index) of scans" :key="index">{{ scan }}</li>
      </small></v-col
    >
    <v-col cols="2">
      <v-container fluid>
        <v-text-field
          v-model="box"
          append-icon="mdi-package-variant"
          :label="record.recordtype == 'stockcheck' ? 'Bin' : 'Box/Palette'"
          single-line
          persistent-hint
          :hint="overwritebox ? 'Fill Up Box Mode' : ''"
          outlined
        ></v-text-field>

        <v-switch
          class="ma-0"
          v-if="
            record.recordtype == 'deliverycheck' &&
            record.location &&
            ['5e0dbaba9e33df43f0b3a495', '62de6196fa2fc522dc692c7e'].includes(
              record.location._id
            )
          "
          v-model="print"
          label="Print Label"
        ></v-switch>
      </v-container>
    </v-col>
    <v-spacer></v-spacer>
    <v-col>
      <v-container fluid>
        <v-btn
          v-if="!['stockcheck'].includes(record.recordtype)"
          :disabled="record.archived"
          @click="selectall"
          block
          tile
          x-large
          :color="confirmed ? 'success' : 'primary'"
          >{{ confirmed ? "Confirmed All" : "Confirm All" }}</v-btn
        >
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import recalcRecord from "../../../common/validations/recalcRecord";
import ding from "../../../assets/ding.mp3";
export default {
  props: ["record", "selected"],
  data() {
    return {
      mode: "barcode",
      items: [],
      scan: "",
      overwritebox: false,
      box: "",
      confirmed: false,
      scans: [],
      qty: 1,
      print:
        this.record.recordtype == "deliverycheck" &&
        this.record.location &&
        this.record.location._id == "5e0dbaba9e33df43f0b3a495"
          ? true
          : false,
      includeredarc: false,
      redarcitem: null,
      redarcitems: [],
      redarcbox: null,
      redarcboxdialog: false,
      redarcselected: [],
      redarcinbox: [],
    };
  },
  watch: {
    mode(v) {
      this.redarcboxdialog = false;
      this.redarcitem = null;
      this.redarcbox = null;
      this.redarcselected = [];
      this.redarcinbox = [];
      this.redarcitems = [];
    },
    box(value) {
      if (this.record.recordtype == "deliverycheck") {
        this.record.box = value;
        this.overwritebox = !this.record["deliveryrecords"].some((item) => {
          return item.box && !item.overwritebox;
        });
      }
      if (this.record.recordtype == "stockcheck") this.record.box = value;
    },
    redarcboxdialog(v) {
      if (v && this.redarcbox) this.getredarcinbox(this.redarcitem);
    },
    redarcbox(v) {
      this.redarcselected = [];
      this.redarcinbox = [];
    },
  },
  created() {
    if (
      this.record.location &&
      this.record.location._id == "61e14c6ae4a35a668517a8fe"
    )
      this.mode = "name";

    // sprawdza czy na transakcji jest Redarc
    if (this.record && this.record["transactionlines"]) {
      this.includeredarc = this.record["transactionlines"].some(
        (item) =>
          item &&
          item.manufacturergroup &&
          item.manufacturergroup._id == "5f9bdaf85e3dc023c4682867"
      );
    }
  },
  computed: {
    duplicates() {
      if (this.record["uniquelabels"]) {
        let array = this.record["uniquelabels"].filter((l) => !l.confirmed);
        const values = new Set();
        for (const item of array) {
          if (values.has(item.value)) {
            return true; // Znaleziono duplikat, zwracamy `true`
          }
          values.add(item.value);
        }
        return false; // Nie znaleziono duplikatów, zwracamy `false`
      } else return false;
    },
  },
  methods: {
    addRedarcLabels() {
      this.redarcselected.forEach((index) => {
        this.record["uniquelabels"].push({
          parameter: this.redarcinbox[index].parameter,
          value: this.redarcinbox[index].value,
          box: `${this.redarcbox}`,
        });
      });
      this.qty = this.redarcselected.length;
      let scan = `${this.redarcitem.name} x${this.qty} (from Box no. ${this.redarcbox} )`;
      this.redarcboxdialog = false;

      this.decodeBarcode(this.redarcitem);

      this.qty = null;

      this.scans.push(scan);
      if (this.scans.length > 3) this.scans.shift();
      if (this.record.recordtype == "deliverycheck") {
        this.redarcbox++;
      } else {
        this.redarcbox = null;
      }
    },
    async getredarcinbox(value) {
      this.redarcinbox = await service("labels", {
        action: "find",
        data: [
          { parameter: value.name, box: this.redarcbox, quantity: { $gt: 0 } },
        ],
        limit: -1,
      });

      let filter = this.redarcinbox.map((e) => e.value);
      let redarcinboxout = await service("labels", {
        action: "find",
        data: [
          {
            parameter: value.name,
            value: { $in: filter },
            quantity: { $lt: 0 },
          },
        ],
        limit: -1,
      });
      this.redarcselected = [];
      this.redarcinbox.forEach((e, index) => {
        let out = redarcinboxout.find((d) => d.value == e.value);
        if (!out) this.redarcselected.push(index);
        else e.out = out.created;
      });
    },
    async searchRecords() {
      await this.getRedarcItems();
    },
    async lastBox(value) {
      this.loading = true;
      try {
        if (value && this.record["deliveryrecords"]) {
          let box = await service("labels", {
            action: "find",
            data: [
              {
                parameter: value.name,
                box: { $exists: true },
                quantity: { $gt: 0 },
              },
            ],
            sort: { _id: -1 },
            limit: -1,
          });
          box
            .sort((a, b) => parseInt(a.box, 10) - parseInt(b.box, 10))
            .reverse();
          if (box.length) this.redarcbox = parseInt(box[0].box || 0) + 1;
          else this.redarcbox = 1;
        }
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },
    async getRedarcItems() {
      this.loading = true;
      try {
        let items = (this.record["transactionlines"] || []).map(
          (l) => l.item._id
        );
        if (this.record["deliveryrecords"])
          items = this.record["deliveryrecords"].map((l) => l.item._id);
        this.redarcitems = await service("items", {
          action: "find",
          data: [
            {
              //name: { $regex: `.*${this.redarcitem}.*`, $options: "i" },
              _id: { $in: items },
              recordtype: { $in: ["inventoryitem", "kititem"] },
              manufacturergroup: "5f9bdaf85e3dc023c4682867",
            },
          ],
        });
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async getItems(item) {
      if (item._id) item = item.name;
      this.items = await service("items", {
        action: "find",
        data: [
          {
            recordtype: { $in: ["inventoryitem", "kititem"] },
            $or: [
              { name: { $regex: `.*${item}.*`, $options: "i" } },
              { displayname: { $regex: `.*${item}.*`, $options: "i" } },
            ],
          },
        ],
        limit: 100,
      }).then((response) => {
        response.forEach((v) => {
          v.name = v.displayname || v.name;
        });
        return response;
      });
    },
    async decodeBarcode(e, scan) {
      let internaluniq = null;
      let uniqLabel = null;
      let redarcscan = null;

      if (this.redarcitem) {
        e = this.redarcitem;
        redarcscan = scan;
      }

      // hawk exception
      // start with "HWK-"" remove from scan number

      if (scan && scan.substring(0, 4) == "HWK-") scan = scan.slice(4);

      if ((scan && e.keyCode === 13) || (e && e._id)) {
        let mode = "barcode";
        let qty = parseInt(this.qty) || 1;
        if (e._id) {
          mode = "name";
          // paremetr w przypadku wywowłania poprzez uniqlabel
          scan = e.name || e.parameter;
        }
        this.scans.push(scan);
        this.scan = "";
        let delivery = this.record.delivery ? this.record.delivery.name : null;
        let item = null;
        let scanparts = scan.split(";");

        if (scanparts.length > 1) {
          scan = scanparts[1];
          internaluniq = scanparts[0];
        }
        let found = false;
        let items =
          this.record["transactionlines"] ||
          this.record["deliveryrecords"] ||
          this.record["stockchecks"];
        let index = 0;
        for (let line of items) {
          index++;
          if (!line.item) line.item = {};
          if (
            (scan ==
              (mode == "name"
                ? line.item.name
                : line.barcode || line.item.barcode) ||
              scan == line.item.name) &&
            (this.record.box
              ? this.record.box == line.box || this.overwritebox
              : true)
          ) {
            found = true;
            //item = line.item.name;
            line.quantity = line.quantity ? line.quantity : 0;
            let foundline = false;
            let negative = false;
            if (
              this.record.recordtype == "inventorytransfer" &&
              line.createdfromline.parentrecordtype != "inventorytransfer"
            )
              negative = true;
            if (Math.abs(line.quantity) + 1 > Math.abs(line.quantitytopack)) {
              for (let i = index; i < items.length; i++) {
                let subline = items[i];
                if (
                  (mode == "name"
                    ? subline.item.name
                    : subline.barcode || subline.item.barcode) == scan &&
                  (this.record.box ? this.record.box == subline.box : true) &&
                  subline._id != line._id &&
                  Math.abs(subline.quantity) < Math.abs(subline.quantitytopack)
                ) {
                  line = subline;
                }
              }
            }
            //console.log(foundline)
            if (!foundline) {
              if (!this.redarcboxdialog) {
                if (negative) line.quantity = line.quantity - qty;
                else line.quantity = line.quantity + qty;
              }
              if (this.record.recordtype == "deliverycheck" && this.box) {
                if (line.box) {
                  line.box = line.box.toString().split(",");
                  if (!line.box.includes(this.box)) line.box.push(this.box);
                } else line.box = this.box;
                line.box = line.box.toString();
                line.overwritebox = true;
              }
              if (this.record.recordtype == "itemfulfillment" && this.box) {
                // if (line.box) {
                //   line.box = line.box.toString().split(",");
                //   if (!line.box.includes(this.box)) line.box.push(this.box);
                // } else line.box = this.box;
                // line.box = line.box.toString();

                if (this.box && line["transaction_box"]) {
                  let updated = false;
                  line["transaction_box"].forEach((b) => {
                    if (b.box == this.box) {
                      updated = true;
                      b.quantity = b.quantity + qty;
                      b.name = `${this.box} (${b.quantity})`;
                      b.displayname = `${this.box} (${b.quantity})`;
                    }
                  });
                  if (!updated) {
                    line["transaction_box"].push({
                      box: this.box,
                      quantity: qty,
                      name: `${this.box} (${qty})`,
                      displayname: `${this.box} (${qty})`,
                    });
                  }
                } else {
                  line["transaction_box"] = [
                    {
                      box: this.frombox,
                      quantity: line.quantitytopack,
                      name: `${this.frombox} (${line.quantitytopack - sum})`,
                      displayname: `${this.frombox} (${
                        line.quantitytopack - sum
                      })`,
                    },
                  ];
                }
              }
            }
            line.confirmed =
              line.quantity == line.quantitytopack ? true : false;
            if (
              line.item &&
              line.item.recordtype == "kititem" &&
              this.record.recordtype == "itemfulfillment"
            ) {
              items.forEach((subline) => {
                if (subline.init == line._id) {
                  subline.quantity = subline.componentquantity * line.quantity;
                }
              });
            }
            if (line.init && this.record.recordtype == "itemfulfillment") {
              let components = [];
              let kitline = false;
              items.forEach((subline) => {
                if (subline._id == line.init) kitline = subline;
                if (subline.init == line.init) {
                  components.push(
                    parseInt(subline.quantity || 0) / subline.componentquantity
                  );
                }
              });

              kitline.quantity = Math.floor(Math.min(...components));
              kitline.confirmed =
                kitline.quantity == kitline.quantitytopack ? true : false;
            }
            if (line.item && this.print && this.record.location) {
              this.printLabel(
                line.item.name,
                scan,
                delivery,
                line.item,
                this.record.location,
                line.bin
              );
            }
            // if (internaluniq) {
            //   this.record["uniquelabels"].push({
            //     parameter: line.item.name,
            //     value: internaluniq,
            //     confirmed: true,
            //   });
            // }
            item = line.item;
            break;
          }
        }
        if (!found && this.record.recordtype == "itemfulfillment") {
          uniqLabel = await this.checkVendorUniqLabels(scan);

          if (uniqLabel && !internaluniq) {
            this.record["uniquelabels"].push({
              parameter: uniqLabel.parameter,
              value: scan,
              box: uniqLabel.box,
            });
            await this.decodeBarcode(uniqLabel);
          } else {
            item = mode == "name" ? e : await this.checkItemByBarcode(scan);
            let newline = await recalcRecord(
              {
                location: this.record.location,
                item: item,
                quantity: qty,
                barcode: mode == "name" ? e.barcode : scan,
                memo: `${item ? "Unexpected" : "Unrecognized"} Item (${scan})`,
              },
              this.record
            );
            this.record["transactionlines"].push(newline);
            this.playSound();
          }
        }
        if (!found && this.record.recordtype == "deliverycheck") {
          item = mode == "name" ? e : await this.checkItemByBarcode(scan);
          //if (item) {
          let newline = {
            location: this.record.location,
            created: "new",
            item: item,
            quantity: qty,
            box: this.record.box,
            barcode: mode == "name" ? e.barcode : scan,
            bin: item
              ? await this.getLocation(item._id, this.record.location._id)
              : "",
          };
          newline = await recalcRecord(newline, false);
          this.record["deliveryrecords"].push(newline);
          if (item) {
            if (this.print && this.record.location) {
              this.printLabel(
                item.name,
                scan,
                delivery,
                item,
                this.record.location,
                newline.bin
              );
            }
          } else {
            this.playSound();
          }
        }
        if (!found && this.record.recordtype == "stockcheck") {
          item = mode == "name" ? e : await this.checkItemByBarcode(scan);
          //if (item) {
          let newline = {
            collection: "items_stockcheck",
            recordtype: "stockcheck",
            location: this.record.location,
            created: "new",
            item: item,
            quantity: qty,
            barcode: mode == "name" ? e.barcode : scan,
            bin: this.box,
            box: this.box,
          };
          newline = await recalcRecord(newline, this.record);
          this.record["stockchecks"].push(newline);
        }

        if (!uniqLabel && this.record["uniquelabels"] && (!e || (e && !e._id)))
          this.record["uniquelabels"].push({
            parameter: item ? item.name || item : "Unrecognized Item",
            value: internaluniq || scan,
            confirmed: true,
          });

        if (e && redarcscan) {
          if (!this.redarcboxdialog) {
            this.record["uniquelabels"].push({
              parameter: scan,
              value: redarcscan,
              box: `${this.redarcbox}`,
            });
          } else {
            this.redarcinbox.push({
              parameter: scan,
              value: redarcscan,
              box: `${this.redarcbox}`,
            });
            this.redarcselected.push(this.redarcinbox.length - 1);
          }
        }

        this.items = [];
        this.qty = 1;
        if (this.scans.length > 3) this.scans.shift();
      }
    },
    async checkVendorUniqLabels(scan) {
      return await service("labels", {
        action: "findOne",
        data: [{ value: scan, confirmed: { $ne: true }, quantity: { $gt: 0 } }],
      })
        .catch((error) => {
          return false;
        })
        .then((response) => {
          return response;
        });
    },
    async checkItemByBarcode(scan) {
      return await service("items", {
        action: "findOne",
        data: [{ $or: [{ barcode: scan }, { name: scan }] }],
      })
        .catch((error) => {
          return null;
        })
        .then((response) => {
          return response;
        });
    },
    playSound(sound) {
      var audio = new Audio(sound || ding);
      audio.play();
    },
    selectall() {
      let lines =
        this.record["transactionlines"] || this.record["deliveryrecords"];
      if (this.selected && this.selected.length) lines = this.selected;
      for (let line of lines) {
        if ((this.box && this.box == line.box) || !this.box) {
          line.quantity = line.quantitytopack;
          line.confirmed = true;
          line.debug_confirmall = true;
        }
      }
      this.confirmed = true;
    },
    async printLabel(name, barcode, delivery, item, location, bin) {
      if (bin) {
        bin = bin.split(",");
        bin = bin[0];
      } else {
        bin = await this.getLocation(item._id, location._id);
        if (location.location && !bin)
          bin = await this.getLocation(item._id, location.location);
      }
      if (name && barcode) {
        service("items", {
          action: "toPrint",
          type: "label",
          name: name,
          delivery: delivery,
          barcode: barcode,
          bin: bin,
        });
      }
    },
    async getLocation(item, location) {
      return await service("items_location", {
        action: "findOne",
        data: [{ item: item, location: location, priority: 1 }],
      })
        .catch((error) => {
          return "";
        })
        .then((res) => {
          return res ? res.bin : "";
        });
    },
  },
};
</script>>